import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const leaveRegisterfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const formFields = [
        {
            label: 'User ID',
            key: 'user_id',
            type: 'select',
            placeholder: 'Enter the user ID',
            visible: true,
            width: '25%',
            options: response.users || [],
        },
        {
            label: 'Leave Request ID',
            key: 'leave_request_id',
            type: 'text',
            placeholder: 'Enter the leave request ID',
            visible: true,
            width: '25%',
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
        },
        {
            label: 'Is Half Day',
            key: 'is_half_day',
            type: 'checkbox',
            placeholder: 'Select if it is a half day',
            visible: true,
            width: '25%',
        },
        {
            label: 'Is Along with PH',
            key: 'is_along_with_ph',
            type: 'checkbox',
            placeholder: 'Select if it is along with PH',
            visible: true,
            width: '25%',
        },
        {
            label: 'Is Along with Weekend',
            key: 'is_along_with_weekend',
            type: 'checkbox',
            placeholder: 'Select if it is along with weekend',
            visible: true,
            width: '25%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const leaveRegisterMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const leaveRegisterMain = {
    fields: leaveRegisterfields,
    mobileCard: null
}






