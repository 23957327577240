import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Colors, View, Text } from 'react-native-ui-lib';

const CustomModal = ({
    visible,
    onClose,
    title,
    children,
    width,
    height,
    backgroundColor,
    overlayColor,
    style,
}) => {
    if (!visible) {
        return null;
    }

    return (<View style={[{
        alignItems: 'center',
        height: height || '100%',
        width: '100%',
    }, style]}>
        <View useSafeArea style={{ width: '100%', height: '100%', alignItems: 'center', }}>

            <TouchableOpacity style={[styles.overlay, {
                backgroundColor: overlayColor ? overlayColor : Colors.rgba(Colors.black, 0.5)
            }]} onPress={() => {
                onClose();
            }}>
            </TouchableOpacity>

            <View row style={{
                width: width || '100%',
                zIndex: 9999,
                // margin : 20,
            }}>
                {/* <TouchableOpacity style={{ 
                    width: 20,
                 }} onPress={() => {
                    onClose();
                }}>
                </TouchableOpacity> */}
                <View style={{ flex: 1, zIndex: 99 }}>
                    {children}
                </View>
                {/* <TouchableOpacity style={{ 
                    width: 20,
                 }} onPress={() => {
                    onClose();
                }}>
                </TouchableOpacity> */}
            </View>
        </View>
    </View>
    );
};

const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        zIndex: 0,
    },
    modal: {
        borderRadius: 5,
        padding: 10,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 10,
    },
});

export default CustomModal;