// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert } from "react-native"

import { api } from "../../../services/api"
import { colors, spacing } from "../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput } from "../../../mycomponents/DynamicForm"
import DynamicTable from "../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "./LayoutScreen"
import FormScreen from "./FormScreen"

const EditScreen = observer(function EditScreen(props) {
    const { formStore: { getOpenModals, setOpenModal, getModalHistory, deleteModel } } = useStores()
    const { route } = props;

    let params = route?.params ? route?.params : useParams();

    if (Platform.OS === 'web') {
        const fullUrl = new URL(window.location.href).search;

        const queryStringStart = fullUrl.indexOf('?');
        const queryString = fullUrl.substring(queryStringStart);

        const queries = queryString.substring(1);

        let queryParams = {};

        queries.split('&').forEach(pair => {
            const [key, value] = pair.split('=');
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        params = { ...params, ...queryParams }
    }

    const nativeRoute = useNavigationState(state => state);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.push("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.push(path, params);
            }
        }
    };


    return (
        <LayoutScreen>
            <View flex>
                <ScrollView>
                    <FormScreen {...props} />
                </ScrollView>
            </View>
        </LayoutScreen>
    )
})

export default EditScreen

const $container = {
    flex: 1,
}