import React, { useEffect, useState } from "react";
import { View, Card, Text, Button } from "react-native-ui-lib";
import { Col, CustomSelect, CustomTextField, Label, Row } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";
import { addButton } from "../hrms/shiftModel";

export const jobfields = (response, view = false, moduleName = null, OpenModalCustom) => {

    const PerksComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const perks = useForm((state) => state.formObject['perks']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {perks && perks.map((perk, index) => {
                    return <Row key={index} style={{ marginBottom: 10, alignItems: 'center' }} >
                        <Col width={Platform.OS != 'web' ? '100%' : '50%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Name' })}
                                <CustomTextField value={perks.name} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.perks];
                                    newArray[index].name = value;
                                    newObject.perks = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>

                        <View style={{ marginTop: 18 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.perks];
                                newArray.splice(index, 1);
                                newObject.perks = newArray;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    });

    const formFields = [

        {
            type: 'divider',
            heading: 'Basic Job Details',
            visible: true,
            span: 24,
        },
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Enter the job title',
            visible: true,
            width: '25%',
        },
        {
            label: 'Work Type',
            key: 'work_type',
            type: 'select',
            placeholder: 'Enter the Location',
            visible: true,
            width: '25%',
            options: [
                { label: 'On-site', value: 'on_site' },
                { label: 'Remote', value: 'remote' },
                { label: 'Hybrid', value: 'hybrid' },
            ]
        },
        {
            label: 'Location',
            key: 'location',
            type: 'text',
            visible: (connectedObject) => connectedObject.work_type === 'on_site' || connectedObject.work_type === 'hybrid',
            placeholder: 'Enter the Location',
            width: '25%',
        },

        {
            label: 'Employment Type',
            key: 'employment_type',
            type: 'select',
            placeholder: 'Select the employment type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Full-time', value: 'full-time' },
                { label: 'Part-time', value: 'part-time' },
                { label: 'Contract', value: 'contract' },
                { label: 'Freelance', value: 'freelance' },
            ],
        },
        {
            label: 'Joining Period',
            key: 'joining_period',
            type: 'text',
            placeholder: 'Enter the joining period',
            visible: true,
            width: '25%',
        },
        {
            label: 'Skills',
            key: 'skills',
            type: 'select',
            placeholder: 'Select the skills',
            visible: true,
            width: '25%',
            options: response.skills || [],
            multiple: true,
            canCreateNew: true
        },
        {
            label: 'Experience',
            key: 'experience',
            type: 'select',
            placeholder: 'Enter the experience',
            visible: true,
            width: '25%',
            options: [
                { label: 'Internship (0 YOE)', value: 'Internship (0 YOE)' },
                { label: 'Entry Level (0-1 YOE)', value: 'Entry Level (0-1 YOE)' },
                { label: 'Associate (1-3 YOE)', value: 'Associate (1-3 YOE)' },
                { label: 'Mid-Senior Level (3-5 YOE)', value: 'Mid-Senior Level (3-5 YOE)' },
                { label: 'Director (5-10 YOE)', value: 'Director (5-10 YOE)' },
                { label: 'Executive (10+ YOE)', value: 'Executive (10+ YOE)' },
            ],
        },
        {
            label: 'Job Description',
            key: 'job_description',
            type: 'textarea',
            placeholder: 'Enter the job description',
            visible: true,
            width: '100%',
        },
        {
            type: 'divider',
            heading: 'Salary Details',
            visible: true,
            span: 24,
        },
        {
            label: 'Salary From',
            key: 'salary_from',
            type: 'number',
            placeholder: 'Enter the salary from',
            visible: true,
            width: '25%',
        },
        {
            label: 'Salary To',
            key: 'salary_to',
            type: 'number',
            placeholder: 'Enter the salary to',
            visible: true,
            width: '25%',
        },
        {
            label: 'Salary Type',
            key: 'salary_type',
            type: 'select',
            placeholder: 'Select the salary type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Hourly', value: 'hourly' },
                { label: 'Monthly', value: 'monthly' },
                { label: 'Yearly', value: 'yearly' },
            ],
        },
        {
            label: 'Salary Currency',
            key: 'salary_currency',
            type: 'text',
            placeholder: 'Enter the salary currency',
            visible: true,
            width: '25%',
        },
        {
            label: 'Probation Period',
            key: 'probation_period',
            type: 'text',
            placeholder: 'Enter the probation period',
            visible: true,
            width: '25%',
        },
        {
            label: 'Salary During Probation',
            key: 'salary_during_probation',
            type: 'number',
            placeholder: 'Enter the salary during probation',
            visible: true,
            width: '25%',
        },
        {
            type: 'divider',
            heading: 'Company Details',
            visible: true,
            span: 24,
        },
        {
            label: 'Department',
            key: 'department_ids',
            type: 'select',
            placeholder: 'Select the department',
            visible: true,
            width: '25%',
            multiple: true,
            options: response.Departments || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Departments", field_key: 'department_id', array: true, params })
            },
        },
        {
            label: 'Designation',
            key: 'designation_ids',
            type: 'select',
            placeholder: 'Select the designation',
            visible: true,
            width: '25%',
            multiple: true,
            options: response.Designations || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Designations", field_key: 'designation_id', array: true, params })
            },
        },
        // {
        //     label: 'About Company',
        //     key: 'about_company',
        //     type: 'textarea',
        //     placeholder: 'Enter the about company',
        //     visible: true,
        //     width: '25%',
        // },
        {
            label: 'Work Days',
            key: 'work_days',
            type: 'text',
            placeholder: 'Enter the work days',
            visible: true,
            width: '25%',
        },
        {
            label: 'Number of Positions',
            key: 'number_of_positions',
            type: 'number',
            placeholder: 'Enter the number of positions',
            visible: true,
            width: '25%',
        },

        {
            label: 'Education',
            key: 'education',
            type: 'text',
            placeholder: 'Enter the education',
            visible: true,
            width: '25%',
        },

        {
            type: 'divider',
            heading: 'Perks',
            visible: true,
            span: 24,
        },

        {
            type: 'custom',
            key: 'perks',
            component: PerksComponent,
            visible: true,
            span: 24,
            value: []
        },
        addButton('Add Perks', 'add_perks', 'perks')

    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const jobMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const jobMain = {
    fields: jobfields,
    mobileCard: null
}
