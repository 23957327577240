import { Instance, SnapshotOut, types } from "mobx-state-tree"

export const FormObjectModel = types
  .model("FormObject")
  .props({
    formObject: types.optional(types.frozen(), {}),
  })
  .views((store) => ({
    get getFormObject() {
      return store.formObject
    },
  }))
  .actions((store) => ({
    setFormObject(value) {
      store.formObject = { ...store.formObject, ...value }
    }
  }))