import React, { useState, createContext } from 'react';
import { View } from 'react-native-ui-lib';
import { CustomModal } from './DynamicForm';

const CustomModalContext = createContext({});

const CustomModalProvider = ({ children }) => {
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [CustomModalContent, setCustomModalContent] = useState(null);

  const openCustomModal = (component) => {
    setIsCustomModalOpen(true);
    setCustomModalContent(component);
  };

  const closeCustomModal = () => {
    setIsCustomModalOpen(false);
    setCustomModalContent(null);
  };

  return (
    <CustomModalContext.Provider value={{ openCustomModal, closeCustomModal }}>
      {children}
      {isCustomModalOpen && (
        <CustomModal title="" visible={isCustomModalOpen} onClose={closeCustomModal}>
          {CustomModalContent}
        </CustomModal>
      )}
    </CustomModalContext.Provider>
  );
};

export default CustomModalProvider;
export { CustomModalContext };
