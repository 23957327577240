import * as React from 'react';
import {
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    Platform,
    Dimensions,
    Alert,
} from 'react-native';
// import { FirebaseRecaptchaVerifierModal, FirebaseRecaptchaBanner } from 'expo-firebase-recaptcha';
// import { initializeApp, getApp } from 'firebase/app';
// import { getAuth, PhoneAuthProvider, signInWithCredential, RecaptchaVerifier } from 'firebase/auth';
import { api } from '../../../../../services/api';
import { App } from "antd"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GlobalMasterStore } from '../../../../../models/PermissionStore';
import { Col, CustomTextField, Label, PrimaryButton, Row, SecondaryButton } from '../../../../../mycomponents/DynamicForm';
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { useNavigate, useParams } from "react-router-dom"
import { View } from 'react-native-ui-lib';
// Initialize Firebase JS SDK >=9.x.x
// https://firebase.google.com/docs/web/setup

// const firebaseConfig = {
//     apiKey: "AIzaSyChOQ9k1-SJiomGsWmL9a7OY6krOlftPuc",
//     authDomain: "inkappsaccounting.firebaseapp.com",
//     projectId: "inkappsaccounting",
//     storageBucket: "inkappsaccounting.appspot.com",
//     messagingSenderId: "696849635001",
//     appId: "1:696849635001:web:2c51d1048a7d210644ed68",
//     measurementId: "G-7H9124M12E"
// };

// try {
//     initializeApp(firebaseConfig);
// } catch (err) {
//     // ignore app already initialized error in snack
// }

// Firebase references
// const app = getApp();
// const auth = getAuth(app);


export default function LoginPhoneScreen() {
    // Ref or state management hooks
    // const recaptchaVerifier = React.useRef(null);
    const [phoneNumber, setPhoneNumber] = React.useState();
    const [otpSent, setOtpSent] = React.useState();
    const [verificationCode, setVerificationCode] = React.useState();
    const { message, modal } = App.useApp();
    // const [messageDisplay, showMessage] = React.useState();
    // const attemptInvisibleVerification = false;
    const [attemptingLogin, setAttemptingLogin] = React.useState(false);

    const setToken = GlobalMasterStore((state) => state.setToken);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);


    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    // const onSubmit = async () => {
    //     // The FirebaseRecaptchaVerifierModal ref implements the
    //     // FirebaseAuthApplicationVerifier interface and can be
    //     // passed directly to `verifyPhoneNumber`.

    //     let appVerifier = recaptchaVerifier.current;

    //     if (Platform.OS == 'web') {
    //         appVerifier = window.recaptchaVerifier;
    //     }

    //     try {
    //         const phoneProvider = new PhoneAuthProvider(auth);
    //         const verificationId = await phoneProvider.verifyPhoneNumber(
    //             phoneNumber,
    //             appVerifier
    //         );
    //         setVerificationId(verificationId);
    //         if (Platform.OS === 'web') {
    //             message.success('Verification code has been sent to your phone.');
    //         } else {
    //             Alert.alert('Verification code has been sent to your phone.');
    //         }
    //     } catch (err) {
    //         if (Platform.OS === 'web') {
    //             message.error(`Error: ${err.message}`);
    //         } else {
    //             Alert.alert(`Error: ${err.message}`);
    //         }
    //     }
    // };

    // React.useEffect(() => {
    //     if (Platform.OS == 'web') {
    //         window.recaptchaVerifier = new RecaptchaVerifier(
    //             "recaptcha-container",
    //             {
    //                 size: "invisible", // this property is important otherwise the captcha will be displayed on the screen
    //             },
    //             auth
    //         );

    //         window.recaptchaVerifier.verify();
    //     }
    // }, []);

    return (
        <View centerH style={{ width: '100%', padding: 20, paddingTop: 50, height: Dimensions.get('window').height }}>
            <Row style={Platform.OS == 'web' ? { maxWidth: 500, marginHorizontal: 'auto' } : {}}>
                <Col width='100%'>
                    {Label({ label: "Enter phone number" })}
                    {<CustomTextField
                        style={{ marginVertical: 10, fontSize: 17 }}
                        placeholder="9999988888"
                        showCountryCode={true}
                        autoFocus
                        autoCompleteType="tel"
                        keyboardType="phone-pad"
                        textContentType="telephoneNumber"
                        value={phoneNumber}
                        onChange={(value) => {
                            console.log("value", value);
                            setPhoneNumber(value)
                        }}
                    />}

                    <PrimaryButton style={{
                        marginTop: 10,
                        width: '100%',
                    }} label="Send Verification Code" disabled={!phoneNumber} onPress={async () => {
                        try {
                            setAttemptingLogin(true);
                            const res = await api.login({
                                phone_number: phoneNumber,
                                no_check: true,
                                "3345A": true,
                            });

                            if (res.message) {
                                if (Platform.OS === 'web') {
                                    message.success(res.message);
                                } else {
                                    Alert.alert(res.message);
                                }

                                if (res.success) {
                                    setOtpSent(true);
                                }
                            } else {
                                if (Platform.OS === 'web') {
                                    message.success("Something went wrong");
                                } else {
                                    Alert.alert("Something went wrong");
                                }
                            }
                            setAttemptingLogin(false);
                        } catch (err) {
                            if (Platform.OS === 'web') {
                                message.error(`Error: ${err.message}`);
                            } else {
                                Alert.alert(`Error: ${err.message}`);
                            }
                            setAttemptingLogin(false);
                        }
                    }} />

                    {!!otpSent && <View style={{ marginTop: 10, width: '100%' }}>
                        {Label({ label: "Enter Verification code" })}
                        {<CustomTextField
                            style={{ width: '100%', marginVertical: 10, fontSize: 17 }}
                            placeholder="123456"
                            value={verificationCode}
                            onChange={(value) => {
                                setVerificationCode(value)
                            }}
                        />}
                        <PrimaryButton
                            style={{ marginTop: 10, width: '100%' }}
                            label="Confirm Verification Code"
                            disabled={attemptingLogin}
                            onPress={async () => {
                                try {
                                    setAttemptingLogin(true);
                                    const res = await api.login({
                                        code: verificationCode,
                                        phone_number: phoneNumber,
                                        no_check: true,
                                        // "3345A": true,
                                    });

                                    if (res.message) {
                                        if (Platform.OS === 'web') {
                                            message.success(res.message);
                                        } else {
                                            Alert.alert(res.message);
                                        }

                                        if (res.success) {
                                            await AsyncStorage.setItem('token', res.token);
                                            const user_id = res.user._id;
                                            const organizations = res.user.organizations;

                                            api.setTokenToAPI(res.token, organizations[0].id);

                                            console.log("user_id", user_id);
                                            console.log("organizations", organizations);
                                            console.log("organization_id", organizations[0].id);
                                            console.log("employee_id", organizations[0].employee_id);

                                            setToken(res.token);
                                            setGlobalData({
                                                user_id: user_id,
                                                organizations: organizations,
                                                organization_id: organizations[0].id,
                                                employee_id: organizations[0].employee_id,
                                                // user: res.user,
                                            })

                                            navigationFn("dashboard");
                                        }
                                    } else {
                                        if (Platform.OS === 'web') {
                                            message.success("Something went wrong");
                                        } else {
                                            Alert.alert("Something went wrong");
                                        }
                                    }
                                    setAttemptingLogin(false);
                                } catch (err) {
                                    if (Platform.OS === 'web') {
                                        message.error(`Error: ${err.message}`);
                                    } else {
                                        Alert.alert(`Error: ${err.message}`);
                                    }
                                    setAttemptingLogin(false);
                                }
                            }}
                        />
                    </View>}


                    <View center marginV-10 style={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                    }}>
                        <View style={{
                            backgroundColor: "rgb(245, 247, 249)"
                        }}>
                            <Text style={{
                                letterSpacing: 1,
                                fontFamily: 'SourceSansProSemiBold'
                            }}>OR</Text>
                        </View>
                        <View style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 9,
                            bottom: 0,
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: 1,
                            backgroundColor: "#c0c0c059",
                        }}>


                        </View>
                    </View>


                    <SecondaryButton
                        style={{ marginTop: 10, width: '100%' }}
                        label="Login with Email and Password"
                        onPress={() => {
                            navigationFn("loginWithEmail")
                        }}
                    />
                </Col>
            </Row>


        </View>
    );
}
