import { ModalStoreModel } from './ModalStore';
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { AuthenticationStoreModel } from "./AuthenticationStore"
import { FormStoreModel } from './FormStore';
import { LayoutStoreModel } from './LayoutStore';
import { FormObjectModel } from './FormObjectStore';

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
    authenticationStore: types.optional(AuthenticationStoreModel, {}),
    modalStore: types.optional(ModalStoreModel, {}),
    formStore: types.optional(FormStoreModel, {}),
    formObject: types.optional(FormObjectModel, {}),
    layoutStore: types.optional(LayoutStoreModel, {}),
})
