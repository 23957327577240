// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState, ActivityIndicator } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions } from "react-native"

import { api } from "../../../services/api"
import { colors, spacing } from "../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, FloatingButton, TouchableOpacity, Dialog, PanningProvider, Chip, ActionSheet } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomSelect, CustomTextField } from "../../../mycomponents/DynamicForm"
import DynamicTable from "../../../mycomponents/DynamicTable"
import { useNavigation } from '@react-navigation/native';
import Svg, { Path, G, Circle } from "react-native-svg";
import { LinearGradient } from 'expo-linear-gradient'
import { BoxShadow } from 'react-native-shadow'
import { useStores } from "../../../models";
import FormScreen from "./FormScreen"
import { useMatch, useNavigate, useRoutes } from "react-router-dom";
import { Sidebar } from "../../../mycomponents/SideBar"
import { Dropdown } from "antd"
import { Icon } from "react-native-eva-icons"
import { HeaderWeb } from "../../../mycomponents/HeaderWeb"
import LayoutScreen from "./LayoutScreen"
import { FABComponent } from "../../../mycomponents/FABComponent"

const ReportScreen = observer(function ReportScreen(props) {
  let navigation = useNavigation();
  let navigate = null;

  let route = props?.route;

  if (Platform.OS === 'web') {
    navigate = useNavigate();
    let path = window?.location?.pathname;
    route = { name: path.split("/")[1] || 'Items' }
  }

  const navigationFn = (path, params) => {
    if (Platform.OS === 'web') {
      let paramsString = "";
      if (params) {
        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
      }
      navigate("/" + path + paramsString);
    } else {
      if (path.includes("report/")) {
        navigation.push("report", {
          ...params,
          name: path.replace("report/", ""),
        });
      } else {
        navigation.push(path, params);
      }
    }
  };

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [allColumns, setAllColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [groupByModal, setGroupByModal] = useState(false);
  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState({
    field: null,
    label: null,
    operator: null,
    operator_label: null,
    operators: [],
    type: "text",
    value: null
  });

  const getModuleData = async () => {
    setLoading(true);

    let { data, all_columns = [], selected_columns = [], filters } = await api.get(route.name, {
      page: pageIndex,
      limit: pageSize,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      filters: selectedFilters,
      tabFilter: tabFilter,
      search: search,
      groupBy: groupBy
    });

    setRows(data?.data || []);
    setTotal(data?.total || 0);

    selected_columns = selected_columns.filter((column) => {
      if (!column.field) {
        return false;
      }
      if (column.accessor == "actions") {
        return false;
      }
      column.Header = column.label;
      column.accessor = column.field;

      if (column.width) {
        const width = "" + column.width;
        column.width = width.replace('px', '');
      }

      return true;
    });

    if (selected_columns.length === all_columns.length) {
      selected_columns = selected_columns.filter((col) => col.visible);
    }

    selected_columns.push({ Header: (props) => null, accessor: 'actions', width: 75, minWidth: 75 });

    const allColumns = all_columns.map((col, index) => ({
      ...col,
      id: index,
      visible: selected_columns.find((c) => c.field === col.field) ? true : false,
      width: selected_columns.find((c) => c.field === col.field) ? selected_columns.find((c) => c.field === col.field).width : 150,
    }))

    setAllColumns(allColumns || []);
    setSelectedColumns(selected_columns || []);
    setFilters(filters || []);

    setLoading(false);
  }

  const { modalStore: { getOpenModals, setOpenModal }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

  const actionFunctions = [
    {
      label: 'Edit',
      key: 'edit',
      fn: ({ data, updateData, row, row_index }) => {
        if (Platform.OS === 'web') {
          navigationFn('Edit/' + route.name, {
            moduleName: route.name,
            id: row._id,
            goBackAfterSave: true,
            isEdit: true,
          });
        } else {
          navigationFn('Edit', {
            moduleName: route.name,
            isEdit: true,
            id: row._id,
            viewOnly: true,
            goBackAfterSave: true
          });
        }
      }
    },
    {
      label: 'Delete',
      key: 'delete',
      fn: ({ data, updateData, row, row_index }) => {
        api.delete(route.name, row._id).then(() => {
          getModuleData();
        });
      }
    },
    {
      label: 'Clone',
      key: 'clone',
      fn: ({ data, updateData, row, row_index }) => {
        if (Platform.OS === 'web') {
          navigationFn('New/' + route.name, {
            moduleName: route.name,
            id: row._id,
            goBackAfterSave: true,
            isEdit: false,
          });
        } else {
          navigationFn('New', {
            moduleName: route.name,
            isEdit: false,
            id: row._id,
            viewOnly: true,
            goBackAfterSave: true
          });
        }
      }
    }
  ];

  const onCheckboxChange = (newSet) => {
    setIds([...newSet]);
  }

  const saveColumns = async (columns, reload = true) => {
    await api.saveColumns(route.name, {
      columns: columns,
    });

    if (reload) {
      setTimeout(() => {
        getModuleData();
      }, 0);
    }
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      let path = window?.location?.pathname;
      route = { name: path.split("/")[1] || 'Items' };
      getModuleData();

      const results = api.getForm(route.name);
      mobileCard = results.mobileCard;
      tabs = results.tabs;
    } else {
      const results = api.getForm(route.name);
      mobileCard = results.mobileCard;
      tabs = results.tabs;
      getModuleData();
    }

  }, [window?.location?.pathname, search, groupBy, selectedFilters, sortColumn, sortDirection, pageIndex, pageSize]);


  const deleteModules = async () => {
    for await (const id of ids) {
      await api.delete(route.name, id);
    }
    getModuleData();
  }

  const Table = <DynamicTable
    data={rows}
    updateData={(newData) => {
      setRows(newData)
    }}
    columns={selectedColumns}
    allColumns={allColumns}
    hideLabelOnMobile={true}
    CustomMobileCard={mobileCard}
    actions={true}
    actionFunctions={actionFunctions}
    cfAdd={true}
    saveColumns={saveColumns}
    isGrouped={groupBy ? true : false}
    // total={total}
    // pageSize={pageSize}
    // pageIndex={pageIndex}
    // onQueryParams={onQueryParams}
    showCheckbox={true}
    onCheckboxChange={onCheckboxChange}
    onRowClick={(row) => {
      setOpenModal({
        [route.name]: {
          isVisible: true,
          moduleName: route.name,
          id: row._id,
          viewOnly: true,
        }
      })
    }}
    resizable={true}
  />

  const newModule = () => {
    if (Platform.OS === 'web') {
      const query = {}
      const params = window.location.search.substring(1).split('&')
      params.map((param) => {
        const [key, value] = param.split('=')
        if (key) {
          query[key] = value;
        }
      })
      navigationFn('New/' + route.name, {
        moduleName: route.name,
        ...query,
        goBackAfterSave: true
      })

    } else {
      navigationFn('New', {
        moduleName: route.name,
        goBackAfterSave: true
      })
    }
  }
  return (
    <LayoutScreen>
      <ScrollView>
        {/* {Platform.OS == 'web' ?
          <HeaderWeb >

          </HeaderWeb>
          : null} */}

        <View marginT-2 padding-16 style={{ ...$container, height: Dimensions.get('window').height - 80 }}>

          <View row centerH spread>
            <View row centerH>
              <Text h1>{route.name}</Text>

              {(tabs && tabs.length > 0) && <View flex left marginL-10 style={{ zIndex: 2 }} w-100>
                <View flex w-100>
                  <SegmentedControl containerStyle={{ justifyContent: 'center' }} padding-20 initialIndex={0} borderRadius={8} segments={tabs} outlineColor="#E0E0E0" activeColor="rgb(45, 45, 45)" inactiveColor="rgb(45, 45, 45)"
                    onChangeIndex={(index) => {
                      setTabFilter(tabs[index].value);
                    }}
                  />
                </View>
              </View>}
            </View>

            {(Platform.OS == 'web' && !hideNewButton) && <Button label="New" onPress={newModule} style={{ marginLeft: 10 }} />}
          </View>

          <View spread centerV row marginB-15 marginT-10>
            <View left row centerV>
              <View marginR-10>
                <Icon name='search-outline' width={16} height={16} fill='#53535f' />
              </View>

              <TextField placeholder="Search" value={search} onChange={(e) => {
                setSearch(e.nativeEvent.text);
              }} />

              <View marginR-10>
                <View style={{ width: 1, height: 16, backgroundColor: '#e0e0e0', marginHorizontal: 10 }} />
              </View>
            </View>


            {ids.length == 0 ? <View row centerV>
              <Button avoidMinWidth
                borderRadius={4}
                backgroundColor="#fff"
                style={[{
                  boxShadow: '0 2px 0 rgba(0,0,0, 0.015)',
                }, { marginLeft: 10 }]}
                onPress={() => {
                  setShowFilter(true);
                }}
              >
                <Icon name='options-2-outline' width={16} height={16} fill='#53535f' />
                <View marginL-5>
                  <Text>Filter</Text>
                </View>
              </Button>

              <Button avoidMinWidth
                borderRadius={4}
                backgroundColor="#fff"
                style={[{ boxShadow: '0 2px 0 rgba(0,0,0, 0.015)' }, { marginLeft: 10 }]}
                onPress={() => {
                  setGroupByModal(true);
                }}
              >
                <Icon name='options-2-outline' width={16} height={16} fill='#53535f' />
                <View marginL-5>
                  <Text>Group By</Text>
                </View>
              </Button>

              {Platform.OS == 'web' ? <Dropdown
                menu={{
                  items: [{
                    label: 'Excel',
                    onClick: () => {
                      download('excel');
                    }
                  }, {
                    label: 'PDF',
                    onClick: () => {
                      download('pdf');
                    }
                  }]
                }}>
                <Button
                  borderRadius={4}
                  outline
                  outlineColor="#edf2f9"
                  style={[{ boxShadow: '0 2px 0 rgba(0,0,0, 0.015)' }, { marginLeft: 6 }]} avoidMinWidth>
                  <Icon name='cloud-download-outline' width={16} height={16} fill='#53535f' />
                  <View marginL-5>
                    <Text>Export</Text>
                  </View>
                </Button>
              </Dropdown> :
                <Button label="Export" onPress={() => setShowExport(true)} style={{ marginLeft: 10 }} />}

            </View> : <View row centerV>
              <Button
                label="Delete"
                onPress={() => {
                  deleteModules();
                }}
                style={{ marginLeft: 10 }}
              />
            </View>}
          </View>

          <View left flex-5 flexG>
            <View flex style={{ width: '100%' }}>
              {!loading ? Table : <Text>Loading...</Text>}
            </View>
          </View>

          {!hideNewButton && <FABComponent onPress={newModule} />}

          <CustomModal
            title="Apply FIlters"
            visible={showFilter}
            onClose={() => setShowFilter(false)}
          >
            {selectedFilters.length > 0 && <View left marginT-20>
              {selectedFilters.map((filter, index) => (
                <Chip key={index} label={`${filter.label} ${filter.operator_label} ${filter.value}`} onPress={() => {
                  let selectedFiltersNew = [...selectedFilters];
                  selectedFiltersNew.splice(index, 1);
                  setSelectedFilters(selectedFiltersNew);
                }} />
              ))}
            </View>}

            <View row marginT-20>
              <View marginR-10 flex>
                <CustomSelect
                  label="Field"
                  options={filters.map(filter => ({ label: filter.label, value: filter.field }))}
                  value={filter.field}
                  onChange={(value) => {
                    const selectedFilter = filters.find(filter => filter.field === value);
                    const filterNew = { ...filter };
                    filterNew.field = selectedFilter?.field;
                    filterNew.operators = selectedFilter?.operators;
                    filterNew.type = selectedFilter?.type;
                    filterNew.operator = selectedFilter?.operators[0].value;
                    filterNew.route = selectedFilter?.route;
                    filterNew.routeParams = selectedFilter?.routeParams;
                    setFilter(filterNew);
                  }}
                />
              </View>
              <View marginR-10 flex>
                <CustomSelect
                  label="Operator"
                  options={filter.operators || []}
                  value={filter.operator}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.operator = value;
                    setFilter(filterNew);
                  }}
                />
              </View>
              <View flex>
                {filter.type == 'select' && <CustomSelect
                  label="Value"
                  options={filter.options || []}
                  value={filter.value}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.value = value;
                    setFilter(filterNew);
                  }}
                  searchRoute={filter.route}
                  searchRouteParams={filter.routeParams}
                />}

                {filter.type == 'text' && <CustomTextField
                  label="Value"
                  value={filter.value}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.value = value;
                    setFilter(filterNew);
                  }}
                />}

                {filter.type == 'number' && <CustomNumberInput
                  label="Value"
                  value={filter.value}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.value = value;
                    setFilter(filterNew);
                  }}
                />}

                {filter.type == 'date' && <CustomDatePicker
                  label="Value"
                  value={filter.value}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.value = value;
                    setFilter(filterNew);
                  }}
                />}

                {filter.type == 'datetime' && <CustomDateTimePicker
                  label="Value"
                  value={filter.value}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.value = value;
                    setFilter(filterNew);
                  }}
                />}

                {filter.type == 'time' && <CustomTimePicker
                  label="Value"
                  value={filter.value}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.value = value;
                    setFilter(filterNew);
                  }}
                />}

                {filter.type == 'boolean' && <CustomSelect
                  label="Value"
                  options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
                  value={filter.value}
                  onChange={(value) => {
                    const filterNew = { ...filter };
                    filterNew.value = value;
                    setFilter(filterNew);
                  }}
                />}

              </View>
            </View>

            <View marginT-15 row>
              <Button
                marginR-10
                label="Add"
                onPress={() => {
                  let selectedFiltersNew = [...selectedFilters];
                  const label = filters.find(fil => fil.field === filter.field)?.label;
                  const operator_label = filter.operators.find(operator => operator.value === filter.operator)?.label;
                  selectedFiltersNew.push({
                    field: filter.field,
                    label: label,
                    operator: filter.operator,
                    operator_label: operator_label,
                    value: filter.value
                  });

                  setSelectedFilters(selectedFiltersNew);

                  setFilter({
                    field: null,
                    label: null,
                    operator: null,
                    operator_label: null,
                    operators: [],
                    type: "text",
                    value: null
                  });
                }}
              />
              <Button
                outline
                label="Clear All"
                onPress={() => {
                  setSelectedFilters([]);
                }}
              />
            </View>

          </CustomModal>


          <CustomModal
            drawer
            title="Group By"
            visible={groupByModal}
            onClose={() => {
              setGroupByModal(false);
            }}
          >
            <View flex useSafeArea>

              <TouchableOpacity
                onPress={() => {
                  setGroupByModal(false);
                  setGroupBy(null);
                }}
              >
                <View row style={{ cursor: 'grab' }}>
                  <Image assetName="drag" style={{ width: 20, height: 20, opacity: 0.2 }} />
                  <Text text-90N grey10>
                    None
                  </Text>
                </View>
              </TouchableOpacity>

              {allColumns.map((item, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    setGroupByModal(false);
                    setGroupBy(item.field);
                  }}
                >
                  <View row style={{ cursor: 'grab' }}>
                    <Image assetName="drag" style={{ width: 20, height: 20, opacity: 0.2 }} />
                    <Text text-90N grey10 key={item.field}>
                      {item.label}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          </CustomModal>

          <ActionSheet
            useNativeIOS
            visible={showExport}
            title={'Title'}
            message={'Message goes here'}
            cancelButtonIndex={2}
            onDismiss={() => setShowExport(false)}
            options={[
              { label: 'Excel', onPress: () => download('excel') },
              { label: 'PDF', onPress: () => download('pdf') },
              { label: 'Cancel', onPress: () => setShowExport(false) }
            ]}
          />

        </View>
      </ScrollView>
    </LayoutScreen>
  )
})

export default ReportScreen

const $container = {
  flex: 1,
}