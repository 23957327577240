import React, { useEffect } from "react";
import { View, Card, Text, TouchableOpacity } from "react-native-ui-lib";
import DynamicTable from "../../mycomponents/DynamicTable";
import { Icon } from "react-native-eva-icons";
import { create } from 'zustand'
import { Col, CustomSelect, CustomTextField, Label, Row } from "../../mycomponents/DynamicForm";

export const dietPlanfields = (response) => {

  const daysSample = [];

  const DietPlan = ({ field, useForm }) => {
    const days = useForm((state) => state.formObject['days']) || [...daysSample];

    const setFormObject = useForm((state) => state.setFormObject);
    const getFormObject = useForm((state) => state.getFormObject);

    const getDayTitleByIndex = (index) => {
      let week = Math.floor(index / 7) + 1;
      let day = index % 7;

      let dayName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


      if (days.length > 7) {
        return `Week ${week} - ${dayName[day]}`;
      } else {
        return dayName[day];
      }
    }

    return (<>
      <View w-100 flex>
        <View center marginT-15 marginB-15>
          <TouchableOpacity onPress={() => {
            let no_of_meals = (getFormObject().no_of_meals || 0);
            let no_of_days = (getFormObject().no_of_days || 0);

            let days = [];

            for (let i = 0; i < no_of_days; i++) {
              let meals = [];

              for (let j = 0; j < no_of_meals; j++) {
                meals.push({
                  time: '',
                  food: '',
                });
              }

              days.push({
                meals,
              });
            }

            setFormObject({
              days,
            });
          }}
            style={{
              marginRight: 10,
              padding: 3,
              paddingLeft: 10,
              paddingRight: 10,
              borderWidth: 1,
              borderColor: '#dcdcdc75',
              borderStyle: 'solid',
              borderRadius: 4
            }}>
            <Text style={{ color: '#055eb6', fontFamily: 'SourceSansProSemiBold' }}>Generate Plan</Text>
          </TouchableOpacity>
        </View>

        <View w-100 flexG flex>
          {days && days.length > 0 && days.map((day, index) => {
            return <View w-100 key={index} style={{ marginBottom: 20 }}>

              <View style={{ marginTop: 10 }} />

              <Text tabHeading style={{ marginBottom: 5 }}>{getDayTitleByIndex(index)}</Text>

              <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 0,
                marginTop: 10,
                borderStyle: 'dashed',
              }} />

              <View w-100>
                {day.meals && day.meals.length > 0 && day.meals.map((meal, mealIndex) => {
                  return <View w-100 key={mealIndex}>
                    <Row w-100 style={{ alignItems: 'center' }} >
                      <Col width="22%">
                        {Label({ label: 'Meal Type' })}
                        <CustomSelect label="Meal Type" value={meal.meal} options={[
                          { label: 'Breakfast', value: 'Breakfast' },
                          { label: 'Lunch', value: 'Lunch' },
                          { label: 'Dinner', value: 'Dinner' },
                          { label: 'Snacks', value: 'Snacks' },
                        ]}
                          onChange={(value) => {
                            let newDays = [...days];

                            newDays[index].meals[mealIndex].meal = value;
                            setFormObject({
                              days: newDays
                            })
                          }} />
                      </Col>

                      <Col width="66%">
                        {Label({ label: 'Food' })}
                        <CustomTextField label="Food" value={meal.food} onChange={(value) => {
                          let newDays = [...days];

                          newDays[index].meals[mealIndex].food = value;
                          setFormObject({
                            days: newDays
                          })
                        }} />
                      </Col>
                      <Col width="12%">
                        <TouchableOpacity
                          onPress={() => {
                            let days = [...(getFormObject().days || [])];

                            days[index].meals.splice(mealIndex, 1);
                            setFormObject({
                              days
                            })
                          }}
                          style={{
                            padding: 3,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderWidth: 1,
                            borderColor: '#dcdcdc75',
                            borderStyle: 'solid',
                            borderRadius: 4,
                            marginTop: 25
                          }}
                        >
                          <Text style={{ color: '#b60505', fontFamily: 'SourceSansProSemiBold' }}>Remove</Text>
                        </TouchableOpacity>
                      </Col>
                    </Row>
                  </View>
                })}
              </View>

              <View row marginT-10>
                <TouchableOpacity
                  onPress={() => {
                    let days = [...(getFormObject().days || [])];

                    days[index].meals = [...(days[index].meals || []), {}];
                    setFormObject({
                      days
                    })
                  }}
                  style={{
                    marginRight: 10,
                    padding: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderWidth: 1,
                    borderColor: '#dcdcdc75',
                    borderStyle: 'solid',
                    borderRadius: 4
                  }}
                >
                  <Text style={{ color: '#055eb6', fontFamily: 'SourceSansProSemiBold' }}>Add Meal</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    let days = [...(getFormObject().days || [])];

                    days.splice(index, 1);
                    setFormObject({
                      days
                    })
                  }}
                  style={{
                    padding: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    border: '1px solid #dcdcdc75',
                    borderRadius: 4
                  }}
                >
                  <Text style={{ color: '#b60505', fontFamily: 'SourceSansProSemiBold' }}>Remove Day</Text>
                </TouchableOpacity>
              </View>
            </View>
          })}
        </View>
        <View>
          <View row>
            <TouchableOpacity onPress={() => {
              let days = [...(getFormObject().days || [])];

              days.push({
                meals: [{

                }]
              });
              setFormObject({
                days
              })
            }}>
              {/* <Icon name="plus-outline" width={20} height={20} fill="black" /> */}
              <Text style={{ marginBottom: 5 }}>Add Day</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>)
  }


  const formFields = [
    {
      type: 'divider',
      heading: 'Diet Plan Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter Diet Plan Name',
      visible: true,
      width: '25%',
      required: true,
    },

    {
      label: 'Plan Type',
      key: 'plan_type',
      type: 'select',
      placeholder: 'Select Plan Type',
      visible: true,
      width: '25%',
      options: [
        { label: 'Weight Loss', value: 'Weight Loss' },
        { label: 'Weight Gain', value: 'Weight Gain' },
        { label: 'Muscle Gain', value: 'Muscle Gain' },
        { label: 'Strength Gain', value: 'Strength Gain' },
        { label: 'Endurance Gain', value: 'Endurance Gain' },
      ],
      multiple: true,
    },

    {
      label: 'No of Days',
      key: 'no_of_days',
      type: 'number',
      placeholder: 'Enter No of Days',
      visible: true,
      width: '25%',
      value: 7
    },

    {
      label: 'No of Meals Per Day',
      key: 'no_of_meals',
      type: 'number',
      placeholder: 'Enter No of Meals',
      visible: true,
      width: '25%',
      value: 3
    },

    {
      type: 'custom',
      key: 'diet_plan_meals',
      visible: true,
      span: 24,
      component: DietPlan,
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const dietPlanMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const dietPlanMain = {
  fields: dietPlanfields,
  mobileCard: null
}