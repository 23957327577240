import React, { useEffect, useLayoutEffect } from "react";
import { GrapesjsReact } from 'grapesjs-react';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage';
import webpageplugin from 'grapesjs-preset-webpage';
import blocksplugin from 'grapesjs-blocks-basic';
import { Dimensions } from "react-native";
import grapesjs from 'grapesjs';

const GrapesEditor2 = (props) => {
    let { available_variables, html, updateHtml, setObj } = props;

    useLayoutEffect(() => {
        const editor = grapesjs.init({
            container: '#gjs',
            height: '600px',
            width: 'auto',
            storageManager: false,
            plugins: [webpageplugin, blocksplugin],
            components: '<div class="txt-red">Hello world!</div>',
        });

        editor.on('change:changesCount', e => {
            updateHtml(editor.getHtml());
        });
    }, []);

    return <>
        <div id="gjs">

        </div>
    </>
}

export default GrapesEditor2;

