// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, Alert } from "react-native"
import { View, KeyboardAwareScrollView } from 'react-native-ui-lib';
import CustomModal from "../../mycomponents/CustomModal"
import FormScreen from "./CRUD/FormScreen";
import { GlobalModalStore } from "../../models/ModalStore"

const ModalScreen = observer(function ModalScreen() {
    const getOpenModals = GlobalModalStore((state) => state.openModals);
    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const getModalHistory = GlobalModalStore((state) => state.modalHistory);

    const zIndexByModalType = (modalType) => {
        const index = getModalHistory.lastIndexOf(modalType);
        return index !== -1 ? index + 1 : 0;
    };

    const renderForm = (params, moduleName) => {
        const route = { params: { ...params, moduleName } };
        return <FormScreen route={route} />
    }

    const renderModals = () => {
        return getModalHistory.slice().reverse().map((moduleName, index) => {
            return <CustomModal
                key={moduleName}
                style={{ position: 'absolute', top: 0, left: 0, zIndex: zIndexByModalType(moduleName) }}
                visible={getOpenModals[moduleName] ? true : false}
                onClose={() => {
                    setOpenModal({ [moduleName]: null })
                }}>
                <View>
                    <KeyboardAwareScrollView style={{ overflow: 'auto', height: Dimensions.get('window').height }}>
                        {renderForm(getOpenModals[moduleName], moduleName)}
                    </KeyboardAwareScrollView>
                </View>
            </CustomModal>
        });
    };

    const checkedOpenModalLength = (getOpenModals) => {
        return Object.keys(getOpenModals).filter((key) => getOpenModals[key]).length > 0;
    }

    return (<>
        {/* <Text>{JSON.stringify(getOpenModals)}</Text> */}
        {/* <Text>{JSON.stringify(getOpenModals)}</Text>
        <Text>{JSON.stringify(getModalHistory)}</Text>
        <TouchableOpacity style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            zIndex: 0,
            heigth: '100%',
        }} onPress={() => {
            setOpenModal({ Locations: null, Item: null, location: null, item: null })
        }}>
        </TouchableOpacity> */}

        {checkedOpenModalLength(getOpenModals) ?
            <View useSafeArea style={{
                height: Dimensions.get('window').height, width: Dimensions.get('window').width,
                position: Platform.OS === 'web' ? 'fixed' : 'absolute',
                left: 0, top: 0, zIndex: 8888,
            }}>
                <View style={{ flex: 1 }}>
                    {checkedOpenModalLength(getOpenModals) ? <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99999 }}>
                        {renderModals()}
                    </View> : <></>}
                </View>
            </View>
            : null}
    </>);
});

export default ModalScreen;