import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { Platform } from "react-native"
import { create } from 'zustand'

export const ModalStoreModel = types
  .model("ModalStore")
  .props({
    openModals: types.optional(types.frozen(), {}),
    modalHistory: types.optional(types.array(types.string), []),
    openSomething: types.optional(types.frozen(), {}),
  })
  .views((store) => ({
    get getOpenModals() {
      return store.openModals
    },
    get getModalHistory() {
      return store.modalHistory
    },
    get getOpenSomething() {
      return store.openSomething
    }
  }))
  .actions((store) => ({
    setOpenModal(value) {
      store.openModals = { ...store.openModals, ...value }
      // const oldHistory = store.modalHistory;
      // const moduleName = Object.keys(value)[0]

      // const moduleData = value[moduleName];

      // if (moduleData == null) {
      //   const index = oldHistory.indexOf(moduleName);
      //   if (index > -1) {
      //     oldHistory.splice(index, 1);
      //   }
      //   oldHistory.replace([...new Set(oldHistory)]);
      //   store.modalHistory = oldHistory;
      //   return;
      // }

      // const index = oldHistory.indexOf(moduleName);
      // if (index > -1) {
      //   oldHistory.splice(index, 1);
      // }

      // oldHistory.push(moduleName);

      // if (oldHistory.length > 10) {
      //   oldHistory.shift()
      // }

      // oldHistory.replace([...new Set(oldHistory)]);

      // store.modalHistory = oldHistory;
    },
    closeAllModals() {
      store.openModals = {}
    },
    setOpenSomething(value) {
      store.openSomething = { ...value }
    }
  }))

export const GlobalModalStore = create((set, get) => ({
  openModals: {},
  modalHistory: [],
  openSomething: {},
  setOpenModal: (value) => {
    set((state) => {
      let oldHistory = [...state.modalHistory];
      const moduleName = Object.keys(value)[0]
      const moduleData = value[moduleName];
      if (moduleData == null) {
        const index = oldHistory.indexOf(moduleName);
        if (index > -1) {
          oldHistory.splice(index, 1);
        }
        oldHistory = [...new Set(oldHistory)];
        return { openModals: { ...state.openModals, ...value }, modalHistory: oldHistory };
      }
      const index = oldHistory.indexOf(moduleName);
      if (index > -1) {
        oldHistory.splice(index, 1);
      }
      oldHistory.push(moduleName);
      if (oldHistory.length > 10) {
        oldHistory.shift()
      }
      oldHistory = [...new Set(oldHistory)];
      const newOpenModals = { ...state.openModals, ...value };
      return { openModals: newOpenModals, modalHistory: oldHistory };
    })
  }
}))