import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const holidayfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
        },
        {
            label: "From",
            key: "from",
            type: "date",
            placeholder: "Enter the from date",
            visible: true,
            width: "25%",
        },
        {
            label: "To",
            key: "to",
            type: "date",
            placeholder: "Enter the to date",
            visible: true,
            width: "25%",
        },
        {
            type: 'divider',
            heading: 'Applicable To',
            visible: true,
            span: 24,
        },
        {
            label: 'Employees',
            key: 'employees',
            type: 'select',
            placeholder: 'Select the employees',
            visible: true,
            width: '100%',
            options: [{ label: 'All', value: 'all' }, ...(response.Employees || [])],
            value: ["all"],
            multiple: true,
            onChange: (props) => {
                const selectedOptions = [...props.value];
                const allIndex = selectedOptions.indexOf('all');

                if (allIndex > -1 && selectedOptions.length > 1) {
                    selectedOptions.splice(allIndex, 1);
                }

                props.setObj({
                    employees: selectedOptions,
                    updateVisiblity: Math.random(),
                })
            }
        },

        {
            label: 'Age (More Than)',
            key: 'age_from',
            type: 'number',
            placeholder: 'Enter the age from',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
        },
        {
            label: 'Age (Less Than)',
            key: 'age_to',
            type: 'number',
            placeholder: 'Enter the age to',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
        },
        {
            width: '25%',
        }, {
            width: '25%'
        },
        {
            label: 'Residency Status',
            key: 'residency_status',
            type: 'select',
            placeholder: 'Select the residency status',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.ResidencyStatus || [],
            multiple: true,
        },
        {
            label: 'Religion',
            key: 'religion',
            type: 'select',
            placeholder: 'Select the religion',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.Religion || [],
            multiple: true,
        },
        {
            label: 'Gender',
            key: 'gender',
            type: 'select',
            placeholder: 'Select the Gender',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.Gender || [],
            multiple: true,
        },
        {
            label: 'Marital Status',
            key: 'marital_status',
            type: 'select',
            placeholder: 'Select the marital status',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.MaritalStatus || [],
            multiple: true,
        },
        {
            label: 'Departments',
            key: 'departments',
            type: 'select',
            placeholder: 'Select the departments',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.Departments || [],
            multiple: true,
        },
        {
            label: 'Designations',
            key: 'designations',
            type: 'select',
            placeholder: 'Select the designations',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.Designations || [],
            multiple: true,
        },
        {
            label: 'Locations',
            key: 'locations',
            type: 'select',
            placeholder: 'Select the Locations',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.Locations || [],
            multiple: true,
        },
        {
            label: 'Roles',
            key: 'roles',
            type: 'select',
            placeholder: 'Select the roles',
            visible: (connectedObject) => (connectedObject.employees && connectedObject.employees.length == 1) ? connectedObject.employees[0] == 'all' : false,
            width: '25%',
            options: response.Roles || [],
            multiple: true,
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const holidayMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const holidayMain = {
    fields: holidayfields,
    mobileCard: null
}