import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../screens/MainScreens/CRUD/ListingScreen";

export const payrollfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const PayrollDetailComponent = ({ field, useForm, tKey, tIndex }) => {
        const _id = useForm((state) => state.formObject['_id']);

        const props = {
            moduleName: 'PayrollDetails',
            hideWrapper: true,
            extraFilters: { payroll_id: _id ?? null },
        }

        return <View w-100 flex>
            <ListingScreen {...props} />
        </View>
    };

    const formFields = [
        {
            label: 'Year',
            key: 'year',
            type: 'number',
            placeholder: 'Enter the year',
            visible: true,
            width: '25%',
        },
        {
            label: 'Month',
            key: 'month',
            type: 'number',
            placeholder: 'Enter the month',
            visible: true,
            width: '25%',
        },

        {
            key: 'payroll_details',
            type: 'custom',
            visible: (obj) => !!obj._id,
            width: '100%',
            component: PayrollDetailComponent,
        },

        {
            label: 'Total Employees',
            key: 'total_employees',
            type: 'number',
            visible: false,
            width: '25%',
        },
        {
            label: 'Processed Payroll',
            key: 'processed_payroll',
            type: 'number',
            visible: false,
            width: '25%',
        },
        {
            label: 'Gross Pay',
            key: 'gross_pay',
            type: 'number',
            visible: false,
            width: '25%',
        },
        {
            label: 'Net Pay',
            key: 'net_pay',
            type: 'number',
            visible: false,
            width: '25%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    // return formFields;
    return formFields;
}

export const payrollMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const payrollMain = {
    fields: payrollfields,
    mobileCard: null
}







