import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const journalItemsfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Entry',
            key: 'entry_id',
            type: 'select',
            placeholder: 'Select the entry',
            visible: true,
            width: '25%',
            options: response.journals || [],
        },
        {
            label: 'Account',
            key: 'account_id',
            type: 'select',
            placeholder: 'Select the account',
            visible: true,
            width: '25%',
            options: response.accounts || [],
        },
        {
            label: 'Debit or Credit',
            key: 'debit_or_credit',
            type: 'select',
            placeholder: 'Select Debit or Credit',
            visible: true,
            width: '25%',
            options: [
                { label: 'Debit', value: 'debit' },
                { label: 'Credit', value: 'credit' },
            ],
        },
        {
            label: 'Display Type',
            key: 'display_type',
            type: 'select',
            placeholder: 'Select Display Type',
            visible: true,
            width: '25%',
            options: [
                { label: 'Normal', value: 'normal' },
                { label: 'Subtotal', value: 'subtotal' },
                { label: 'Total', value: 'total' },
            ],
        },
        {
            label: 'Tax',
            key: 'tax_id',
            type: 'select',
            placeholder: 'Select the tax',
            visible: true,
            width: '25%',
            options: response.taxes || [],
        },
        {
            label: 'Debit',
            key: 'debit',
            type: 'number',
            placeholder: 'Enter the debit amount',
            visible: true,
            width: '25%',
        },
        {
            label: 'Credit',
            key: 'credit',
            type: 'number',
            placeholder: 'Enter the credit amount',
            visible: true,
            width: '25%',
        },
        {
            label: 'Contact',
            key: 'contact_id',
            type: 'select',
            placeholder: 'Select the contact',
            visible: true,
            width: '25%',
            options: response.contacts || [],
            creatableAction: (props) => {
                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? {
                        is_default_values: true, phone: props.search,
                        is_supplier: moduleName == "PaymentReceived" ? true : false,
                        is_customer: moduleName == "PaymentMade" ? true : false
                    } : {})
                };
                OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
            },
        },
        {
            label: 'Item',
            key: 'item_id',
            type: 'select',
            placeholder: 'Select the item',
            visible: true,
            width: '25%',
            options: response.items || [],
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: true,
            width: '25%',
            options: response.locations || [],
        },
        {
            label: 'Matched',
            key: 'matched',
            type: 'checkbox',
            placeholder: 'Matched',
            visible: false,
            width: '25%',
        },
        {
            label: 'Opening Entry',
            key: 'isOpening',
            type: 'checkbox',
            placeholder: 'Opening Entry',
            visible: false,
            width: '25%',
        },

    ];


    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const journalItemsMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const journalItemsMain = {
    fields: journalItemsfields,
    mobileCard: null
}
