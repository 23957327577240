import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const exercisefields = (response) => {

    const formFields = [
        {
            type: 'divider',
            heading: 'Exercise Details',
            visible: true,
            span: 24,
        },

        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter exercise Name',
            visible: true,
            width: '25%',
        },

        {
            label: 'Description',
            key: 'description',
            type: 'text',
            placeholder: 'Enter exercise Description',
            visible: true,
            width: '25%',
        },

        {
            label: 'Image',
            key: 'image',
            type: 'upload',
            visible: true,
            width: '25%',
            mimeType: 'image/*'
        },

        {
            label: 'Video',
            key: 'video',
            type: 'upload',
            placeholder: 'Enter exercise Video',
            visible: true,
            width: '25%',
            mimeType: 'video/*'
        },

        {
            label: 'Body Parts',
            key: 'body_parts',
            type: 'select',
            placeholder: 'Enter exercise Body Parts',
            visible: true,
            width: '25%',
            options: [
                { value: 'Biceps', label: 'Biceps' },
                { value: 'Triceps', label: 'Triceps' },
                { value: 'Shoulders', label: 'Shoulders' },
                { value: 'Chest', label: 'Chest' },
                { value: 'Back', label: 'Back' },
                { value: 'Abs', label: 'Abs' },
                { value: 'Glutes', label: 'Glutes' },
                { value: 'Quadriceps', label: 'Quadriceps' },
                { value: 'Hamstrings', label: 'Hamstrings' },
                { value: 'Calves', label: 'Calves' },
                { value: 'Cardio', label: 'Cardio' },
                { value: 'Full Body', label: 'Full Body' },
            ],
            multiple: true
        },

        {
            label: 'Difficulty Level',
            key: 'difficulty_level',
            type: 'select',
            placeholder: 'Enter exercise Difficulty Level',
            visible: true,
            width: '25%',
            options: [
                { value: 'Beginner', label: 'Beginner' },
                { value: 'Intermediate', label: 'Intermediate' },
                { value: 'Advanced', label: 'Advanced' },
            ],
            multiple: true
        },

        {
            label: 'Equipment',
            key: 'equipment',
            type: 'select',
            placeholder: 'Enter exercise Equipment',
            visible: true,
            width: '25%',
            options: response.Equipments ? response.Equipments.map((equipment) => {
                return { ...equipment, value: equipment.id, label: equipment.name };
            }) : [],
            multiple: true
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const exerciseMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const exerciseMain = {
    fields: exercisefields,
    mobileCard: null
}