import Svg, { Path, G, Circle } from "react-native-svg";
import { LinearGradient } from 'expo-linear-gradient'
import { BoxShadow } from 'react-native-shadow'
import { Platform, TouchableOpacity } from "react-native";
import React from "react";

export const FABComponent = (props) => <BoxShadow setting={{
    width: 20,
    height: 20,
    color: "#00a9df",
    border: 20,
    radius: 10,
    opacity: 0.8,
    x: 0,
    y: 3,
    style: {
        position: 'absolute',
        bottom: 75,
        right: 25,
        width: 30,
        height: 30,
        borderRadius: 50,
    }
}}>
    <LinearGradient colors={['rgba(2, 145, 225,0.6)', 'rgba(2, 145, 225,1)']} style={{
        backgroundColor: 'white',
        height: 50,
        width: 50,
        borderRadius: 50,
        position: 'absolute',
        top: -15,
        left: -15,
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <TouchableOpacity style={{
            height: 50,
            width: 50,
            borderRadius: 50,
            justifyContent: 'center',
            alignItems: 'center'
        }} onPress={() => {
            props?.onPress();
        }}>
            <Svg viewBox="0 0 384 512" style={{ height: 15, width: 15 }}>
                <Path fill="#ffffff"
                    d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" />
            </Svg>
        </TouchableOpacity>
    </LinearGradient>
</BoxShadow>;