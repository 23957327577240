export const auditLogfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'User',
            key: 'user.name',
            type: 'text',
            visible: true,
            width: '25%',
        },
        {
            label: 'Action',
            key: 'action',
            type: 'text',
            visible: true,
            width: '100%',
        },
        {
            label: 'Model',
            key: 'model',
            type: 'text',
            visible: true,
            width: '100%',
        },
        {
            label: 'Model Data',
            key: 'model_data',
            type: 'text',
            visible: true,
            width: '100%',
        },
    ];

    return formFields;
}

export const auditLogMain = {
    fields: auditLogfields,
    mobileCard: null,
    hideNewButton: true,
}