import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const payrollStatutoryComponentfields = (response, view = false, moduleName = null, OpenModalCustom, data) => {

    const EPF_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        },
        {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%'
        },
        {
            width: '50%',
        },
        {
            label: 'EPF Number',
            key: 'options.epf_number',
            type: 'text',
            placeholder: 'Enter the epf number',
            visible: true,
            width: '25%'
        },
        {
            label: 'EPF to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            placeholder: 'Select the epf to be charged on',
            visible: true,
            width: '25%',
            options: [
                { label: 'Basic', value: 'Basic' },
                { label: 'Basic plus Allowances', value: 'Basic plus Allowances' },
                { label: 'Basic plus Allowances Less Deductions', value: 'Basic plus Allowances Less Deductions' },
                { label: 'Prorated Basic', value: 'Prorated Basic' },
                { label: 'Prorated Basic plus Allowances', value: 'Prorated Basic plus Allowances' },
                { label: 'Prorated Basic plus Allowances Less Deductions', value: 'Prorated Basic plus Allowances Less Deductions' },
            ],
            value: 'actual_monthly_gross_pay',
        },
        {
            width: '50%',
        }, {
            label: 'Include employer\'s contribution in the CTC',
            key: 'options.include_employers_contribution_in_the_ctc',
            type: 'checkbox',
            placeholder: 'Select if include employer\'s contribution in the ctc',
            visible: true,
            width: '100%',
            value: false,
        }, {
            label: 'Override contribution at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override pf contribution rate at employee level',
            visible: true,
            width: '100%',
            value: false,
        }, {
            label: 'Eligible for ABRY Scheme',
            key: 'options.eligible_for_abry_scheme',
            type: 'checkbox',
            placeholder: 'Select if eligible for abry scheme',
            visible: true,
            width: '100%',
            value: false,
        }
    ];

    const ESI_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        },
        {
            width: '50%',
        },
        {
            label: 'ESI Number',
            key: 'options.esi_number',
            type: 'text',
            placeholder: 'Enter the esi number',
            visible: true,
            width: '25%',
        },
        {
            label: 'ESI to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            width: '25%',
            options: [
                { label: 'Basic', value: 'Basic' },
                // { label: 'Basic plus Allowances', value: 'Basic plus Allowances' },
                // { label: 'Basic plus Allowances Less Deductions', value: 'Basic plus Allowances Less Deductions' },
                { label: 'Basic Prorated', value: 'Basic Prorated' },
                // { label: 'Prorated Basic plus Allowances', value: 'Prorated Basic plus Allowances' },
                // { label: 'Prorated Basic plus Allowances Less Deductions', value: 'Prorated Basic plus Allowances Less Deductions' },
            ],
            value: 'actual_monthly_gross_pay',
        },
        {
            label: 'Include employer\'s contribution in the CTC',
            key: 'options.include_employers_contribution_in_the_ctc',
            type: 'checkbox',
            placeholder: 'Select if include employer\'s contribution in the ctc',
            visible: true,
            width: '100%',
            value: false,
        }, {
            label: 'Override ESI contribution rate at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override esi contribution rate at employee level',
            visible: true,
            width: '100%',
            value: false,
        }
    ];

    const PT_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }, {
            width: '50%',
        }, {
            label: 'PT Number',
            key: 'options.pt_number',
            type: 'text',
            placeholder: 'Enter the esi number',
            visible: true,
            width: '25%',
        }
    ];

    const Labour_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }
    ]

    // Singapore Statutory
    const SDL_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }, {
            width: '50%',
        }, {
            label: 'SDF to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            width: '25%',
            options: [
                { label: 'Basic', value: 'Basic' },
                { label: 'Basic plus Allowances', value: 'Basic plus Allowances' },
                { label: 'Basic plus Allowances Less Deductions', value: 'Basic plus Allowances Less Deductions' },
                { label: 'Prorated Basic', value: 'Prorated Basic' },
                { label: 'Prorated Basic plus Allowances', value: 'Prorated Basic plus Allowances' },
                { label: 'Prorated Basic plus Allowances Less Deductions', value: 'Prorated Basic plus Allowances Less Deductions' },
            ],
            value: 'actual_monthly_gross_pay',
        }, {
            label: 'Include SDF contribution in the CTC',
            key: 'options.include_employers_contribution_in_the_ctc',
            type: 'checkbox',
            placeholder: 'Select if include employer\'s contribution in the ctc',
            visible: true,
            width: '100%',
        }, {
            label: 'Override SDF contribution amount at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override SDF contribution rate at employee level',
            visible: true,
            width: '100%',
        }
    ];

    const SINDA_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }, {
            label: 'SINDA to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            width: '25%',
            options: [
                { label: 'Actual Monthly Gross Pay', value: 'actual_monthly_gross_pay' },
                { label: 'Salary Mentioned in CTC', value: 'salary_mentioned_in_ctc' },
            ],
            value: 'actual_monthly_gross_pay',
        }, {
            label: 'Override SINDA contribution amount at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override esi contribution rate at employee level',
            visible: true,
            width: '25%',
        }
    ];

    const MBMF_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }, {
            label: 'Override MBMF contribution amount at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override MBMF contribution rate at employee level',
            visible: true,
            width: '25%',
        }, {
            label: 'MBMF to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            width: '25%',
            options: [
                { label: 'Actual Monthly Gross Pay', value: 'actual_monthly_gross_pay' },
                { label: 'Salary Mentioned in CTC', value: 'salary_mentioned_in_ctc' },
            ],
        }
    ];

    const CDAC_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }, {
            label: 'Override CDAC contribution amount at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override CDAC contribution rate at employee level',
            visible: true,
            width: '25%',
        }, {
            label: 'CDAC to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            width: '25%',
            options: [
                { label: 'Actual Monthly Gross Pay', value: 'actual_monthly_gross_pay' },
                { label: 'Salary Mentioned in CTC', value: 'salary_mentioned_in_ctc' },
            ],
        }
    ];

    const ECF = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }, {
            label: 'Override ECF contribution amount at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override ECF contribution rate at employee level',
            visible: true,
            width: '25%',
        }, {
            label: 'ECF to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            width: '25%',
            options: [
                { label: 'Actual Monthly Gross Pay', value: 'actual_monthly_gross_pay' },
                { label: 'Salary Mentioned in CTC', value: 'salary_mentioned_in_ctc' },
            ],
        }
    ];

    const CPF_Form = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }, {
            label: 'Override CPF contribution amount at employee level',
            key: 'options.override_contribution_at_employee_level',
            type: 'checkbox',
            placeholder: 'Select if override CPF contribution rate at employee level',
            visible: true,
            width: '25%',
        }, {
            label: 'CPF to be charged on',
            key: 'options.to_be_charged_on',
            type: 'select',
            width: '25%',
            options: [
                { label: 'Actual Monthly Gross Pay', value: 'actual_monthly_gross_pay' },
                { label: 'Salary Mentioned in CTC', value: 'salary_mentioned_in_ctc' },
            ],
        }
    ];

    const INCOME_TAX_FORM = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the name',
            visible: true,
            width: '25%',
            disabled: true,
        }, {
            label: 'Enabled',
            key: 'enabled',
            type: 'checkbox',
            placeholder: 'Select if enabled',
            visible: true,
            width: '25%',
        }
    ];

    const nameFormMap = {
        'EPF': EPF_Form,
        'ESI': ESI_Form,
        'Professional Tax': PT_Form,
        'Labour Welfare Fund': Labour_Form,
        'Income Tax': INCOME_TAX_FORM,

        'SDL': SDL_Form,
        'SINDA': SINDA_Form,
        'MBMF': MBMF_Form,
        'CDAC': CDAC_Form,
        'ECF': ECF,
        'CPF': CPF_Form,
    };

    return nameFormMap[data?.name] || [{
        label: 'Name',
        key: 'name',
        type: 'text',
        placeholder: 'Enter the name',
        visible: true,
        width: '25%'
    }];
}

export const payrollStatutoryComponentMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const payrollStatutoryComponentMain = {
    fields: payrollStatutoryComponentfields,
    mobileCard: null
}



