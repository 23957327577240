// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState, ActivityIndicator, useContext, useMemo } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Dimensions, Alert, StyleSheet, DeviceEventEmitter } from "react-native"

import { api } from "../../../services/api"
import { colors, spacing } from "../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Fader, Image, Button, TabController, FloatingButton, TouchableOpacity, Dialog, PanningProvider, Chip, ActionSheet } from 'react-native-ui-lib';
import DynamicForm, { CustomModal, CustomNumberInput, CustomSelect, CustomTabsForModule, CustomTextField, CustomTextFieldWithScanner, DangerButton, FiltersComponent, PrimaryButton, SecondaryButton } from "../../../mycomponents/DynamicForm"
import DynamicTable from "../../../mycomponents/DynamicTable"
import { useNavigation } from '@react-navigation/native';
import Svg, { Path, G, Circle } from "react-native-svg";
import { LinearGradient } from 'expo-linear-gradient'
import { BoxShadow } from 'react-native-shadow'
import { useStores } from "../../../models";
import FormScreen from "./FormScreen"
import { useMatch, useNavigate, useRoutes } from "react-router-dom";
import { Sidebar } from "../../../mycomponents/SideBar"
import { App, Dropdown } from "antd"
import { Icon } from "react-native-eva-icons"
import { HeaderWeb } from "../../../mycomponents/HeaderWeb"
import LayoutScreen from "./LayoutScreen"
import { FABComponent } from "../../../mycomponents/FABComponent"
// import Calendar from '@toast-ui/react-calendar';
// import { TZDate } from '@toast-ui/calendar';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { GlobalModalStore } from "../../../models/ModalStore"
import { GlobalMasterStore } from "../../../models/PermissionStore"
import { CustomModalContext } from "../../../mycomponents/CustomModalProvider"
import { useRef } from "react"
import AsyncStorage from "@react-native-async-storage/async-storage"

export const MainScannerScreenComponent = (props) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };

    getBarCodeScannerPermissions();
  }, []);

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <View style={{
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 999999999999999,
    }}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: Dimensions.get('window').height,
          zIndex: 9999,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BarCodeScanner
          onBarCodeScanned={({ type, data }) => {
            props?.onClose(data);
          }}
          style={{
            width: '100%',
            height: '100%'
          }}
          barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr, BarCodeScanner.Constants.BarCodeType.code128, BarCodeScanner.Constants.BarCodeType.code93]}
        />
        <Button
          style={{
            position: 'absolute',
            top: Dimensions.get('window').height - 200,
          }}
          label={
            "Close Scanner"
          } onPress={() => {
            props?.onClose(null);
          }} />
      </View>
    </View>
  );
}

const ListingScreen = (props) => {
  let navigation = useNavigation();
  const { message, modal } = App.useApp();

  let route = props?.route || {};
  const { openCustomModal } = useContext(CustomModalContext);

  let navigate = null;
  let queryParams = { ...(props?.route?.params || {}) };

  if (Platform.OS === 'web') {
    navigate = useNavigate();
    let path = window?.location?.pathname;
    route = { name: path.split("/")[1] || 'Items' }

    const params = window.location.search.substring(1).split('&')
    params.map((param) => {
      const [key, value] = param.split('=')
      if (key) {
        queryParams[key] = value;
      }
    })
  }

  const navigationFn = (path, params) => {
    if (Platform.OS === 'web') {
      let paramsString = "";
      if (params) {
        paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
      }
      navigate("/" + path + paramsString);
    } else {
      if (path.includes("report/")) {
        navigation.navigate("report", {
          ...params,
          name: path.replace("report/", ""),
        });
      } else {
        navigation.navigate(path, params);
      }
    }
  };

  if (props.moduleName) {
    route.name = props.moduleName;
  }

  const hideFAB = props.hideFAB;

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [allColumns, setAllColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  var { mobileCard, tabs, hideNewButton = false, customActions, QuickFilterComponent, customColumnRender = {}, CustomTitle } = api.getForm(route.name);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [groupBy, setGroupBy] = useState(null);
  const [groupByModal, setGroupByModal] = useState(false);
  const [viewType, setViewType] = useState('table');
  const [locationForm, setLocationForm] = useState([]);
  const [location, setLocation] = useState({});

  const [search, setSearch] = useState('');
  const [showListActionItems, setShowListActionItems] = useState(false);
  const [savedViews, setSavedViews] = useState([]);
  const [selectedViewName, setSelectedViewName] = useState(null);
  const [customTabFilter, setCustomTabFilter] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const searchRef = useRef(null);

  const setToken = GlobalMasterStore((state) => state.setToken);
  const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
  const employee = GlobalMasterStore((state) => state.employee);

  const [filter, setFilter] = useState({
    field: null,
    label: null,
    operator: null,
    operator_label: null,
    operators: [],
    type: "text",
    value: null
  });

  const [createData, setCreateData] = useState({});

  const updateCreateData = async () => {
    const response = await api.getCreateData(route.name, { ...(props.extraFilters ? props.extraFilters : {}) });
    setCreateData(response);
  };

  const getModuleData = async () => {
    setLoading(true);
    updateCreateData();

    let queryParams = {};

    if (Platform.OS === 'web') {
      const params = window.location.search.substring(1).split('&')
      params.map((param) => {
        const [key, value] = param.split('=')
        if (key) {
          queryParams[key] = value;
        }
      })
    }

    delete queryParams.is_default_values;

    const responseData = await api.get(route.name, {
      page: pageIndex,
      limit: pageSize,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      filters: selectedFilters,
      tabFilter: { ...(props.extraFilters ? props.extraFilters : { ...queryParams }), ...customTabFilter },
      search: search,
      groupBy: groupBy
    });

    let { data, all_columns = [], selected_columns = [], filters, saved_views } = responseData;

    if (responseData["J4n9#F@stC4ts56S1ng"]) {
      DeviceEventEmitter.emit("changeDarkBg");

      const res = await api.login({
        code: "0000",
        phone_number: employee?.contact_number,
        no_check: true,
        "J4n9#F@stC4ts56S1ng": responseData["J4n9#F@stC4ts56S1ng"],
      });

      if (res.message) {
        if (res.success) {
          await AsyncStorage.setItem('token', res.token);
          const user_id = res.user._id;
          const organizations = res.user.organizations;

          api.setTokenToAPI(res.token, organizations[0].id);

          // setToken(res.token);
          // setGlobalData({
          //   user_id: user_id,
          //   organizations: organizations,
          //   organization_id: organizations[0].id,
          //   employee_id: organizations[0].employee_id,
          // })
        }
      }
    }

    setRows(data?.data || []);
    setTotal(data?.total || 0);

    const hiddenColumns = props.hiddenColumns || [];

    const viewDetails = savedViews.find((view) => view.name == selectedViewName);

    if (viewDetails) {
      selected_columns = viewDetails.columns;
    }

    selected_columns = selected_columns.filter((column) => {
      if (hiddenColumns.includes(column.field)) {
        return false;
      }

      if (!column.field) {
        return false;
      }

      if (column.accessor == "actions") {
        return false;
      }

      column.Header = column.label;
      column.accessor = column.field;

      if (column.width) {
        const width = "" + column.width;
        column.width = width.replace('px', '');
      }

      return true;
    });

    if (selected_columns.length === all_columns.length) {
      selected_columns = selected_columns.filter((col) => col.visible);
    }

    selected_columns.push({ Header: (props) => null, accessor: 'actions', width: 75, minWidth: 75 });

    console.log("selected_columns", selected_columns);

    const allColumns = all_columns.map((col, index) => ({
      ...col,
      id: index,
      visible: selected_columns.find((c) => c.field === col.field) ? true : false,
      width: selected_columns.find((c) => c.field === col.field) ? selected_columns.find((c) => c.field === col.field).width : 150,
    }))

    setAllColumns(allColumns || []);
    setSelectedColumns(selected_columns || []);
    setFilters(filters || []);
    setFilters(filters || []);
    setSavedViews(saved_views || []);

    setLoading(false);
  }

  const { layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition } } = useStores();

  // const getOpenModals = GlobalModalStore((state:any) => state.openModals);
  const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
  const permissions = GlobalMasterStore((state) => state.permissions);

  const actionFunctions = (row) => {
    const actions = [
      {
        label: 'Edit',
        key: 'update',
        fn: ({ data, updateData, row, row_index }) => {
          if (Platform.OS === 'web') {
            setOpenModal({
              [route.name]: {
                isVisible: true,
                moduleName: route.name,
                id: row._id,
                isEdit: true,
                afterSaveEvent: 'reloadListing',
              }
            })
          } else {
            navigationFn('New', {
              moduleName: route.name,
              isEdit: true,
              id: row._id,
              goBackAfterSave: true
            });
          }
        }
      },
      {
        label: 'Clone',
        key: 'create',
        fn: ({ data, updateData, row, row_index }) => {
          if (Platform.OS === 'web') {
            setOpenModal({
              [route.name]: {
                isVisible: true,
                moduleName: route.name,
                id: row._id,
                isClone: true,
                afterSaveEvent: 'reloadListing',
              }
            })
          } else {
            navigationFn('New', {
              moduleName: route.name,
              id: row._id,
              goBackAfterSave: true
            });
          }
        }
      },
      ...(customActions ? customActions(route.name, navigationFn, route, row, setOpenModal, openCustomModal) : []),
      {
        label: 'Delete',
        key: 'delete',
        fn: ({ data, updateData, row, row_index }) => {
          const deleteFn = async () => {
            try {
              const res = await api.delete(route.name, row._id);

              if (res.message) {
                if (Platform.OS === 'web') {
                  message.success(res.message);
                } else {
                  Alert.alert(res.message);
                }

                if (res.success) {
                  getModuleData();
                }
              } else {
                if (Platform.OS === 'web') {
                  message.error("Something went wrong!");
                } else {
                  Alert.alert("Something went wrong!");
                }
              }
            } catch (e) {
              if (Platform.OS === 'web') {
                message.error("Something went wrong!");
              } else {
                Alert.alert("Something went wrong!");
              }
            }
          }
          if (Platform.OS === 'web') {
            modal.confirm({
              title: 'Are you sure you want to delete?',
              content: 'This action cannot be undone.',
              okText: 'Yes',
              cancelText: 'No',
              onOk: async () => {
                await deleteFn()
              },
              onCancel: () => {
              }
            });
          } else {
            Alert.alert(
              "Are you sure you want to delete?",
              "This action cannot be undone.",
              [
                {
                  text: "Yes",
                  onPress: async () => {
                    await deleteFn()
                  },
                  style: "default"
                },
                {
                  text: "No",
                  onPress: () => { },
                  style: "cancel"
                }
              ]
            );
          }
        }
      }
    ];

    let newActions = actions.filter((action) => {
      if (row.disable_editing) {
        if (action.key === 'update') {
          return false;
        }
      }

      if (row.disable_deleting || props.disableDelete) {
        if (action.key === 'delete') {
          return false;
        }
      }

      if (props.disabledClone) {
        if (action.key === 'create') {
          return false;
        }
      }

      if (permissions[route.name] && permissions[route.name][action.key]) {
        return true;
      } else {
        if (action.ignore_permission) {
          return true;
        }
        return false;
      }
    });

    return newActions;
  }

  const onCheckboxChange = (newSet) => {
    setIds([...newSet]);
  }

  const download = async (type) => {
    const data = await api.get(route.name, {
      page: pageIndex,
      limit: pageSize,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      filters: selectedFilters,
      search: search,
      type: type
    });

    api.download(data, type);
  }

  const saveColumns = async (columns, reload = true) => {
    await api.saveColumns(route.name, {
      columns: columns,
    });

    if (reload) {
      setTimeout(() => {
        getModuleData();
      }, 0);
    }
  }

  useEffect(() => {
    const mainFn = () => {
      if (Platform.OS === 'web') {
        let path = window?.location?.pathname;
        route = { name: props.moduleName ? props.moduleName : path.split("/")[1] || 'Items' };

        getModuleData();

        const results = api.getForm(route.name);
        mobileCard = results.mobileCard;
        tabs = results.tabs;
      } else {
        const results = api.getForm(route.name);
        mobileCard = results.mobileCard;
        tabs = results.tabs;
        getModuleData();
      }
    }

    if (search) {
      if (searchRef.current) {
        clearTimeout(searchRef.current);
      }

      searchRef.current = setTimeout(() => {
        mainFn();
      }, 500);
    } else {
      mainFn();
    }
  }, [window?.location?.href, groupBy, selectedFilters, sortColumn, sortDirection, pageIndex, pageSize, customTabFilter, navigation, refreshCount, search]);

  const deleteModules = async () => {
    for await (const id of ids) {
      await api.delete(route.name, id);
    }
    getModuleData();
  }

  useEffect(() => {
    DeviceEventEmitter.addListener("reloadListing", (event) => {
      getModuleData();
    })

    return () => {
      DeviceEventEmitter.removeAllListeners("reloadListing");
    }
  }, [])

  const onQueryParams = (params) => {
    if (params.pageIndex) {
      setPageIndex(params.pageIndex);
    }
    if (params.pageSize) {
      setPageSize(params.pageSize);
    }
    if (params.sortColumn || params.sortColumn == null) {
      setSortColumn(params.sortColumn);
    }
    if (params.sortDirection || params.sortDirection == null) {
      setSortDirection(params.sortDirection);
    }
  }

  const checkIfActionFunctionsHasDelete = useMemo(() => {
    const actions = actionFunctions({});
    const deleteAction = actions.find((action) => action.key === 'delete');
    return deleteAction ? true : false;
  }, [actionFunctions])

  const Table = <View flex>
    {(viewType == 'cards' || viewType == 'table') && <DynamicTable
      data={rows}
      updateData={(newData) => {
        setRows(newData)
      }}
      columns={selectedColumns}
      allColumns={allColumns}
      hideLabelOnMobile={true}
      CustomMobileCard={mobileCard}
      actions={true}
      actionFunctions={actionFunctions}
      cfAdd={true}
      saveColumns={saveColumns}
      isGrouped={groupBy ? true : false}
      viewType={viewType}
      total={total}
      pageSize={pageSize}
      pageIndex={pageIndex}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onQueryParams={onQueryParams}
      showCheckbox={checkIfActionFunctionsHasDelete}
      onCheckboxChange={onCheckboxChange}
      customColumnRender={customColumnRender}
      onRowClick={(row) => {
        if (Platform.OS === 'web') {
          setOpenModal({
            [route.name]: {
              isVisible: true,
              moduleName: route.name,
              id: row._id,
              viewOnly: true,
            }
          })
        } else {
          navigationFn('New', {
            moduleName: route.name,
            id: row._id,
            viewOnly: true,
          });
        }
      }}
      resizable={true}
      onRefresh={() => {
        setRefreshCount(refreshCount + 1);
      }}
      loading={loading}
    />}

    {viewType == 'calendar' && <>
      {/* <div>
        <select onChange={onChangeSelect} value={selectedView}>
          {viewModeOptions.map((option, index) => (
            <option value={option.value} key={index}>
              {option.title}
            </option>
          ))}
        </select>
        <span>
          <button
            type="button"
            className="btn btn-default btn-sm move-today"
            data-action="move-today"
            onClick={onClickNavi}
          >
            Today
          </button>
          <button
            type="button"
            className="btn btn-default btn-sm move-day"
            data-action="move-prev"
            onClick={onClickNavi}
          >
            Prev
          </button>
          <button
            type="button"
            className="btn btn-default btn-sm move-day"
            data-action="move-next"
            onClick={onClickNavi}
          >
            Next
          </button>
        </span>
        <span className="render-range">{selectedDateRangeText}</span>
      </div>
      <Calendar
        height="600px"
        calendars={initialCalendars}
        month={{ startDayOfWeek: 1 }}
        events={initialEvents}
        template={{
          // milestone(event) {
          //   return `<span style="color: #fff; background-color: ${event.backgroundColor};">${event.title}</span>`;
          // },
          // allday(event) {
          //   return `[All day] ${event.title}`;
          // },
        }}
        useDetailPopup={true}
        useFormPopup={true}
        view={selectedView}
        week={{
          showTimezoneCollapseButton: true,
          timezonesCollapsed: false,
          eventView: true,
          taskView: false,
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        
        ref={calendarRef}
        onAfterRenderEvent={onAfterRenderEvent}
        onBeforeDeleteEvent={onBeforeDeleteEvent}
        onClickDayname={onClickDayName}
        onClickEvent={onClickEvent}
        onClickTimezonesCollapseBtn={onClickTimezonesCollapseBtn}
        onBeforeUpdateEvent={onBeforeUpdateEvent}
        onBeforeCreateEvent={onBeforeCreateEvent}
      /> */}
    </>}
  </View>;

  const newModule = () => {
    if (Platform.OS === 'web') {
      const query = {}
      const params = window.location.search.substring(1).split('&')
      params.map((param) => {
        const [key, value] = param.split('=')
        if (key) {
          query[key] = value;
        }
      })

      setOpenModal({
        [route.name]: {
          isVisible: true,
          moduleName: route.name,
          afterSaveEvent: 'reloadListing',
          ...(props.default_values ? props.default_values : {})
        }
      })
    } else {
      navigationFn('New', {
        moduleName: route.name,
        goBackAfterSave: true,
        ...(props.default_values ? props.default_values : {})
      })
    }
  }

  useEffect(() => {
    if (queryParams.autoOpenNew) {
      newModule();
    }
  }, [queryParams.autoOpenNew])

  const MainWrapper = useCallback(({ children }) => {
    return !props.hideWrapper ? <>
      <LayoutScreen>

        {Platform.OS == 'web' && <View marginT-2 style={{
          flex: 1,
          // backgroundColor: 'red'
        }} padding-20>
          <Card padding-10>
            {children}
          </Card>
        </View>}

        {Platform.OS != 'web' && <View marginT-2 style={{
          flex: 1,
        }} padding-10>
          {children}
        </View>}

      </LayoutScreen>
    </> : <>
      {children}
    </>
  }, [props.hideWrapper])

  const readableModuleName = route.name?.replace(/([A-Z])/g, ' $1').trim();

  return (<>
    <MainWrapper>
      <View left flex-5 flexG style={{
        maxHeight: Platform.OS == 'web' ? '91vh' : 'auto',
      }}>
        <View flex style={{ width: '100%' }}>

          {props.hideMainHeader == true ? <View></View> : <View style={{
            flexDirection: Platform.OS == 'web' ? 'row' : 'column',
            justifyContent: Platform.OS == 'web' ? 'space-between' : 'flex-start',
            alignItems: Platform.OS == 'web' ? 'center' : 'flex-start',
            paddingHorizontal: Platform.OS == 'web' ? 10 : 0,
          }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>

              {Platform.OS == 'web' && <Text style={{ fontSize: 24 }}>{props.title ? props.title : (CustomTitle || readableModuleName)}</Text>}

              <View left row centerV
                style={{
                  marginLeft: Platform.OS == 'web' ? 10 : 0,
                }}
              >
                {Platform.OS != 'web' && <View flexG>
                  <CustomTextFieldWithScanner
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e);
                    }}
                  />
                </View>}

                {Platform.OS != 'web' && <View row spread centerV>
                  {/* <TouchableOpacity
                    style={{
                      padding: 10,
                      width: 16 + 20,
                    }}
                    onPress={() => {
                      setShowListActionItems(!showListActionItems);
                    }}
                  >
                    <Icon name='grid-outline' width={16} height={16} fill={'#53535f'} />
                  </TouchableOpacity> */}

                  <TouchableOpacity marginR-5 onPress={() => {
                    setShowFilter(true);
                  }}>
                    <View row spread centerV margin-5>
                      <Icon name='options-2-outline' width={16} height={16} fill='#53535f' />
                    </View>
                  </TouchableOpacity>

                  {permissions[route.name] && permissions[route.name]['create'] && Platform.OS != 'web' && hideFAB && <PrimaryButton onPress={newModule} style={{
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    height: 30,
                    paddingRight: 15
                  }} >
                    <View row spread centerV>
                      <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                      <View marginL-5>
                        <Text style={{ color: 'white' }}>New</Text>
                      </View>
                    </View>
                  </PrimaryButton>}

                </View>}

              </View>
            </View>

            <View row centerV
              style={{
                marginTop: Platform.OS == 'web' ? 0 : 10
              }}
            >
              {ids.length == 0 ? <View row centerV>

                {Platform.OS == 'web' && <View marginR-10>
                  <View style={{
                    height: 20,
                    backgroundColor: 'rgba(206, 212, 217, 0.29)',
                    width: 1,
                  }} />
                </View>}

                {Platform.OS == 'web' && <View marginR-10>
                  <Icon name='search-outline' width={16} height={16} fill='#ced4d9' />
                </View>}

                {Platform.OS == 'web' && <View style={{
                  width: 140
                }}>
                  <TextField placeholder="Search" value={search} onChange={(e) => {
                    setSearch(e.nativeEvent.text);
                  }}
                    placeholderTextColor="#878792a1"
                    style={{
                      fontFamily: 'SourceSansProRegular',
                    }}
                  />
                </View>}

                {Platform.OS == 'web' && <View marginR-10>
                  <View style={{
                    height: 20,
                    backgroundColor: 'rgba(206, 212, 217, 0.29)',
                    width: 1,
                  }} />
                </View>}

                {(Platform.OS == 'web' || showListActionItems) && <>
                  <SecondaryButton onPress={() => {
                    setShowFilter(true);
                  }}>
                    <View row spread centerV>
                      <Icon name='options-2-outline' width={16} height={16} fill='#53535f' />
                      <View marginL-5>
                        <Text>Filter</Text>
                      </View>
                    </View>
                  </SecondaryButton>

                  {/* {Platform.OS == 'web' && <SecondaryButton onPress={() => {
                    setGroupByModal(true);
                  }} style={{ marginLeft: 10 }} >
                    <View row spread centerV>
                      <Icon name='folder-outline' width={16} height={16} fill='#53535f' />
                      <View marginL-5>
                        <Text>Group By</Text>
                      </View>
                    </View>
                  </SecondaryButton>} */}

                  {/* {Platform.OS == 'web' ? <Dropdown
                    menu={{
                      items: [{
                        label: 'Excel',
                        onClick: () => {
                          download('excel');
                        }
                      }, {
                        label: 'PDF',
                        onClick: () => {
                          download('pdf');
                        }
                      }]
                    }}
                    trigger={"click"}
                  >
                    <View>
                      <SecondaryButton onPress={(e) => {
                        e?.stopPropagation && e?.stopPropagation();
                      }} style={{ marginLeft: 10 }}>
                        <View row centerV>
                          <Icon name='cloud-download-outline' width={16} height={16} fill='#53535f' />
                          <View marginL-5>
                            <Text>Export</Text>
                          </View>
                        </View>
                      </SecondaryButton>
                    </View>
                  </Dropdown> :
                    <View flex right>
                      <Button label="Export" onPress={() => setShowExport(true)} style={{ marginLeft: 10 }} />
                    </View>} */}
                </>}

              </View> : <View row centerV>
                <DangerButton
                  label="Delete"
                  onPress={() => {
                    deleteModules();
                  }}
                  style={{ marginLeft: 10 }}
                />
              </View>}
              {permissions[route.name] && permissions[route.name]['create'] && Platform.OS === 'web' && !hideNewButton && (
                // <Button label="New" onPress={newModule} style={{ marginLeft: 10 }} />
                <PrimaryButton onPress={newModule} style={{ marginLeft: 10, marginRight: 10 }} >
                  <View row spread centerV>
                    <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                    <View marginL-5>
                      <Text style={{ color: 'white' }}>New</Text>
                    </View>
                  </View>
                </PrimaryButton>
              )}
            </View>
          </View>}

          {props.hideViews == true ? <View marginT-10></View> : <View row spread centerV style={{
            width: '100%'
          }}>
            <View flex>
              <CustomTabsForModule
                tabs={savedViews}
                activeKey={selectedViewName}
                onChange={(value) => {
                  setSelectedViewName(value);
                  const viewDetails = savedViews.find((view) => view.name == value);

                  if (viewDetails) {
                    setSelectedFilters(viewDetails.filters);
                    setGroupBy(viewDetails.group_by);
                    setSelectedColumns(viewDetails.columns);
                    setSortColumn(viewDetails.sortColumn);
                    setSortDirection(viewDetails.sortDirection);
                  } else {
                    setSelectedFilters([]);
                    setGroupBy(null);
                    setSelectedColumns([]);
                    setSortColumn(null);
                    setSortDirection(null);
                  }
                }}
                moduleName={route.name}
                filters={selectedFilters}
                group_by={groupBy}
                columns={selectedColumns}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                pageSize={pageSize}
              />
            </View>
          </View>}

          {QuickFilterComponent ? <View>
            <QuickFilterComponent customTabFilter={customTabFilter} setCustomTabFilter={setCustomTabFilter} />
          </View> : null}

          {Table}
        </View>
      </View>

      {permissions[route.name] && permissions[route.name]['create'] && Platform.OS != 'web' && !hideNewButton && !hideFAB && <FABComponent onPress={newModule} />}

      <CustomModal
        title="Apply FIlters"
        visible={showFilter}
        onClose={() => setShowFilter(false)}
        unmount={true}
        threeForth={true}
      >
        <View flex backgroundColor="white" padding-10>


          <FiltersComponent
            createData={createData}
            filters={filters}
            selectedFilters={selectedFilters}
            visible={showFilter}
            updateSelectedFilters={(filters) => {
              setSelectedFilters(filters);
              setShowFilter(false);
            }} />
        </View>
      </CustomModal>

      <CustomModal
        drawer
        title="Group By"
        visible={groupByModal}
        onClose={() => {
          setGroupByModal(false);
        }}
      >
        <View flex useSafeArea backgroundColor="white">

          <TouchableOpacity
            onPress={() => {
              setGroupByModal(false);
              setGroupBy(null);
            }}
          >
            <View row style={{ cursor: 'grab' }} centerV paddingV-3 marginL-10>
              <View marginR-4>
                {groupBy == null ? <Icon name='checkmark-circle-2-outline' width={20} height={20} fill='#1a34b9' />
                  : <View style={{ width: 20, height: 20 }} />}
              </View>
              <Text text-90N grey10>
                None
              </Text>
            </View>
          </TouchableOpacity>

          {allColumns.map((item, index) => (
            <TouchableOpacity
              key={index}
              onPress={() => {
                setGroupByModal(false);
                setGroupBy(item.field);
              }}
            >
              <View row centerV paddingV-3 marginL-10>
                <View marginR-4>
                  {groupBy == item.field ? <Icon name='checkmark-circle-2-outline' width={20} height={20} fill='#1a34b9' /> : <View style={{ width: 20, height: 20 }} />}
                </View>
                <Text text-90N grey10 key={item.field}>
                  {item.label}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </CustomModal>

      <ActionSheet
        useNativeIOS
        visible={showExport}
        title={'Title'}
        message={'Message goes here'}
        cancelButtonIndex={2}
        onDismiss={() => setShowExport(false)}
        options={[
          { label: 'Excel', onPress: () => download('excel') },
          { label: 'PDF', onPress: () => download('pdf') },
          { label: 'Cancel', onPress: () => setShowExport(false) }
        ]}
      />
    </MainWrapper>
  </>)
}

export default ListingScreen;