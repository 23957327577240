import { useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { TouchableOpacity, StyleSheet, Platform, ScrollView } from 'react-native';
import { Colors, View, Text, ExpandableSection, Button, Image } from 'react-native-ui-lib';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../models';
import { observer } from 'mobx-react-lite';

export const HeaderWeb = observer(function HeaderWeb(props) {
    const { layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed } } = useStores();

    const nativeRoute = useNavigationState(state => state);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            navigate("/" + path);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    return (<View useSafeArea backgroundColor='white'>
        <View row padding-10>
            <View>
                {props.children}
            </View>
            <View flex-1 row spread>
                <View>

                </View>
                <View>
                    <Text>YAHA AYEGA LOGOUT</Text>
                </View>
            </View>
        </View>
    </View>);
})

const styles = StyleSheet.create({
    MenuText: { marginLeft: 8, fontSize: 14, color: '#415669', letterSpacing: 0.6 },
    MenuHead: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        marginRight: 15,
        marginTop: 10,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        paddingVertical: 8,
    },
    highlightMenuItem: {
        backgroundColor: 'white',
        elevation: 5,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.10,
        shadowRadius: 3.84,
    },
});