// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, RefreshControl } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, Avatar, TouchableOpacity } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, DangerButton, Heading, Row, SecondaryButton, CustomTextField, Label, CustomCheckbox } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../models"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import { GlobalMasterStore } from "../../../../../models/PermissionStore"
import dayjs from "dayjs"
import { Camera, CameraType } from 'expo-camera';
import Svg, { G, Path } from "react-native-svg"
import Header from "./Header"
import Footer from "./Footer"
import { Icon } from "react-native-eva-icons"
import { App } from "antd"
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { ProductCard } from "./Home"
import RenderHTML from "react-native-render-html"

const isDesktop = Dimensions.get('window').width >= 768;

const AboutUs = (props) => {
    const { message, modal } = App.useApp();
    const [product, setProduct] = useState({});
    const [currentImage, setCurrentImage] = useState(0);
    const cart = GlobalMasterStore(state => state.cart);
    const setCart = GlobalMasterStore(state => state.setCart);

    const [relatedProducts, setRelatedProducts] = useState([]);

    const wishList = GlobalMasterStore(state => state.wishList);
    const setWishList = GlobalMasterStore(state => state.setWishList);

    const params = useParams();

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const title = "About us";
    const content = `<p>Ikaki was founded in Bhopal, the culturally vibrant birthplace of our founder Apurvi Vyas. Having grown up appreciating the rich heritage and artisanal traditions of her hometown, Apurvi was inspired to celebrate individuality and time-honored craftsmanship.</p>
    <p>She firmly believed that each one of us is ikaki - meaning one-of-a-kind. Just as no two individuals are exactly alike, their style and self-expression should be just as unique.</p>
    <p>With this guiding vision, Apurvi set out on a journey to build Ikaki - a brand that would recreate heirloom designs into trendy accessories that appeal to both classic and modern sensibilities.</p>
    <h2>Our Vision</h2>
    <p>At Ikaki, our vision is to cherish your individuality and make bespoke fashion effortlessly accessible. We are on a mission to take age-old handicrafts and artforms from the breadth of India, and reimagine them into contemporary pieces that speak to the ancient Indian soul within you.</p>
    <p>Every Ikaki creation celebrates diversity, unconventionality, and the richness of our shared roots. We believe that fashion must enable self-expression and evoke a sense of pride in our communities, craftsmanship, and culture.</p>
    <h2>Our Design Philosophy</h2>
    <p>We follow an intentional design philosophy focused on merging India's design heritage with current trends. Our talented karigars (artisans) handcraft the Ikaki collection using traditional techniques perfected over generations. The materials and methods we use honor our roots while the silhouettes and styles keep up with today's sensibilities.</p>
    <p>By finding the harmony between old and new, our products redefine India's rich textile language into a fashionable, modern vocabulary that is uniquely yours. When you wear Ikaki, you bring to life the soul of the past while celebrating your originality. You become a part of our shared story - a story worth telling for generations to come.</p>`;

    return (<>
        <ScrollView style={{
            height: Dimensions.get("window").height,
        }}>
            <Card>
                <View style={{
                    position: 'relative',
                }}>
                    <Header theme={"white_bg"} />
                    <View style={[{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        overflow: "hidden",
                        height: 70,
                        minHeight: 70,
                        borderWidth: 1,
                        borderColor: "#e5e7eb",
                    }]}>
                    </View>
                </View>
                <Row>
                    <View style={{
                        width: '100%',
                        padding: isDesktop ? 50 : 20,
                    }}>
                        <View row={isDesktop} style={{
                            width: '100%'
                        }}>
                            <Col width={isDesktop ? "100%" : '100%'}
                                style={{
                                    paddingLeft: isDesktop ? 40 : 0,
                                }}>
                                <View style={{
                                    paddingBottom: 5,
                                }}>
                                    <Text style={{
                                        color: "#000",
                                        fontSize: 24,
                                        fontFamily: 'SourceSansProBold'
                                    }}>{title}</Text>
                                </View>


                                <View style={{
                                    paddingBottom: 15,
                                }}>
                                    {Platform.OS == 'web' ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <RenderHTML contentWidth={Dimensions.get('window').width - 40} source={{ html: content }} />}
                                </View>
                            </Col>
                        </View>
                    </View>
                </Row>

                <Footer />
            </Card >
        </ScrollView >
    </>);
};

export default AboutUs;