import { Instance, SnapshotOut, types } from "mobx-state-tree"

export const LayoutStoreModel = types
  .model("LayoutStore")
  .props({
    sideBarCollapsed: types.optional(types.boolean, false),
    sideBarOpen: types.optional(types.boolean, false),
    sideBarPosition: types.optional(types.string, "left"),
    currentTab: types.optional(types.string, "none"),
    darkBg: types.optional(types.boolean, false)
  })
  .views((store) => ({
    get isSideBarCollapsed() {
      return store.sideBarCollapsed
    },
    get isSideBarOpen() {
      return store.sideBarOpen
    },
    get getSideBarPosition() {
      return store.sideBarPosition
    },
    get getCurrentTab() {
      return store.currentTab
    },
    get isDarkBg() {
      return store.darkBg
    }
  }))
  .actions((store) => ({
    toggleSideBarCollapsed() {
      store.sideBarCollapsed = !store.sideBarCollapsed
    },
    toggleSideBarOpen() {
      store.sideBarOpen = !store.sideBarOpen
    },
    closeSideBar() {
      store.sideBarOpen = false
    },
    setSideBarPosition(position) {
      store.sideBarPosition = position
    },
    setCurrentTab(tab) {
      store.currentTab = tab
    },
    setDarkBg(darkBg) {
      store.darkBg = darkBg
    }
  }))