import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { Col, CustomCheckbox, Row } from "../../mycomponents/DynamicForm";

export const notificationTemplatefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const TemplateComponent = ({ field, useForm, tKey, tIndex }) => {
    return <View>
      {/* <GrapesjsReact
                id='grapesjs-react'
                width={Dimensions.get('window').width - 380 + 'px'}
                onInit={(editor) => {
                    editor.setComponents(html, { avoidInlineStyle: false });
                }}
                plugins={[
                    webpageplugin,
                    blocksplugin
                ]}
            /> */}
    </View>
  }

  const formFields = [
    {
      type: 'divider',
      heading: 'Notification Template Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter notificationTemplate Name',
      visible: true,
      width: '25%',
      required: true,
      editable: false,
      disabled: true
    },

    {
      width: '100%',
      key: 'email_template',
      type: 'custom',
      visible: true,
      component: TemplateComponent,
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const notificationTemplateMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const notificationTemplateMain = {
  fields: notificationTemplatefields,
  mobileCard: null
}