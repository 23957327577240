import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const fifoProductInfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
        },
        {
            label: 'Is Out',
            key: 'is_out',
            type: 'number',
            placeholder: 'Enter the is out',
            visible: true,
            width: '25%',
        },
        {
            label: 'Lot No',
            key: 'lot_no',
            type: 'number',
            placeholder: 'Enter the lot no',
            visible: true,
            width: '25%',
        },
        {
            label: 'Transaction Type',
            key: 'transaction_type',
            type: 'text',
            placeholder: 'Enter the transaction type',
            visible: true,
            width: '25%',
        },
        {
            label: 'Transaction ID',
            key: 'transaction_id',
            type: 'select',
            placeholder: 'Select the transaction ID',
            visible: true,
            width: '25%',
            options: response.transactions || [],
        },
        {
            label: 'Transaction Number',
            key: 'transaction_number',
            type: 'text',
            placeholder: 'Enter the transaction number',
            visible: true,
            width: '25%',
        },
        {
            label: 'Contact',
            key: 'contact_id',
            type: 'select',
            placeholder: 'Select the contact',
            visible: true,
            width: '25%',
            options: response.contacts || [],
        },
        {
            label: 'Item ID',
            key: 'item_id',
            type: 'select',
            placeholder: 'Select the item ID',
            visible: true,
            width: '25%',
            options: response.items || [],
        },
        {
            label: 'Serial Number',
            key: 'serial_number',
            type: 'text',
            placeholder: 'Enter the serial number',
            visible: true,
            width: '25%',
        },

        {
            label: 'Location ID',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location ID',
            visible: true,
            width: '25%',
            options: response.locations || [],
        },
        {
            label: 'Quantity In',
            key: 'quantity_in',
            type: 'number',
            placeholder: 'Enter the quantity in',
            visible: true,
            width: '25%',
        },
        {
            label: 'Entry ID',
            key: 'entry_id',
            type: 'text',
            placeholder: 'Enter the entry ID',
            visible: true,
            width: '25%',
        },
        {
            label: 'Cost Per Unit',
            key: 'cost_per_unit',
            type: 'number',
            placeholder: 'Enter the cost per unit',
            visible: true,
            width: '25%',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const fifoProductInMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const fifoProductInMain = {
    fields: fifoProductInfields,
    mobileCard: null
}
