import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { Col, CustomCheckbox, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, Row } from "../../mycomponents/DynamicForm";

export const approvalWorkFlowfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const LevelsComponent = ({ field, useForm, tKey, tIndex }) => {
        let levels = useForm((state) => state.formObject['levels']) || [];
        const setFormObject = useForm((state) => state.setFormObject);

        const addLevel = () => {
            const oldLevels = [...(levels || [])];
            const newLevel = {
                approver_type: '',
                approvers: [],
            }
            oldLevels.push(newLevel);
            setFormObject({
                levels: oldLevels
            });
        }

        const removeLevel = (index) => {
            const oldLevels = [...(levels || [])];
            oldLevels.splice(index, 1);
            setFormObject({
                levels: oldLevels
            });
        };

        return <View style={{ marginBottom: 20 }} flex>
            <View style={{ marginTop: 10 }} />
            <Text tabHeading style={{ marginBottom: 5 }}>Approver Levels</Text>

            <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 0,
                marginTop: 10,
                borderStyle: 'dashed',
            }} />

            <View flex style={{ width: '100%' }} marginT-20>
                {levels && levels.length > 0 && levels.map((level, levelIndex) => {
                    return <View key={levelIndex.toString()} flex>
                        <Row style={{ alignItems: 'center', width: '100%' }} >
                            <Col width="15%">
                                {Label({ label: 'Level' })}
                                <Text formlabel style={{ marginBottom: 5, lineHeight: 32 }}>{levelIndex + 1}</Text>
                            </Col>

                            <Col width="70%">
                                {Label({ label: 'Approvers' })}
                                <CustomSelect value={level.approvers}
                                    options={[
                                        { label: 'Specific Field', value: 'specific-field' },

                                        { label: 'One - Position Up', value: 'One Position Up' },
                                        { label: 'Two - Positions Up', value: 'Two Positions Up' },
                                        { label: 'Three - Positions Up', value: 'Three Positions Up' },

                                        { label: 'One - Manager Up', value: 'One Manager Up' },
                                        { label: 'Two - Managers Up', value: 'Two Managers Up' },
                                        { label: 'Three - Managers Up', value: 'Three Managers Up' },

                                        // { label: 'Position - HR', value: 'position-1' },
                                        // { label: 'Position - Admin', value: 'position-2' },

                                        ...response.positions.map((item) => {
                                            return { label: 'Position - ' + item.name, value: 'position-' + item._id }
                                        }),

                                        // { label: 'Role - HR', value: 'role-1' },
                                        // { label: 'Role - Admin', value: 'role-2' },

                                        ...response.roles.map((item) => {
                                            return { label: 'Role - ' + item.name, value: 'role-' + item._id }
                                        }),

                                        // { label: 'Employee - Faizan', value: 'employee-Faizan' },
                                        // { label: 'Employee - Umar', value: 'employee-Umar' },

                                        ...response.employees.map((item) => {
                                            return { label: 'Employee - ' + item.name, value: 'employee-' + item._id }
                                        }),
                                    ]}
                                    onChange={(value) => {
                                        const oldLevels = [...(levels || [])];
                                        oldLevels[levelIndex].approvers = value;
                                        setFormObject({
                                            levels: oldLevels
                                        });
                                    }}
                                    multiple
                                />

                                {level.approvers && level.approvers.filter((item) => item == 'specific-field').length > 0 &&
                                    <View style={{ marginTop: 10, width: '100%' }}>
                                        <CustomTextField
                                            label="Specific Field"
                                            placeholder="Enter the specific field"
                                            value={level.specific_field}
                                            onChange={(value) => {
                                                const oldLevels = [...(levels || [])];
                                                oldLevels[levelIndex].specific_field = value;
                                                setFormObject({
                                                    levels: oldLevels
                                                });
                                            }}
                                        />
                                    </View>}


                            </Col>

                            <Col width="15%">
                                <DangerButton
                                    style={{
                                        marginTop: 22,
                                    }}
                                    label="Remove"
                                    onPress={() => {
                                        removeLevel(levelIndex);
                                    }}
                                />
                            </Col>
                        </Row>
                    </View>
                })}
            </View>

            <View left marginT-10>
                <PrimaryButton
                    label="Add Level"
                    onPress={() => {
                        addLevel();
                    }}
                />
            </View>


        </View>
    }


    const formFields = [
        {
            type: 'divider',
            heading: 'Approval WorkFlow Details',
            visible: true,
            span: 24,
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter Name',
            visible: true,
            width: '25%',
            required: true,
        },
        {
            label: '',
            key: 'levels',
            type: 'custom',
            component: LevelsComponent,
            visible: true,
            width: '100%',
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const approvalWorkFlowMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const approvalWorkFlowMain = {
    fields: approvalWorkFlowfields,
    mobileCard: null
}