import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";

export const leaveRequestfields = (response, view = false, moduleName = null, OpenModalCustom) => {
    const formFields = [
        {
            label: 'Leave Category',
            key: 'leave_category_id',
            type: 'select',
            placeholder: 'Select the leave category',
            visible: true,
            width: '25%',
            options: response.LeaveCategories || []
        },

        {
            label: 'Employee',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select the user',
            visible: response.isAdmin ? true : false,
            width: '25%',
            options: response.Employees || [],
            value: response.isAdmin ? null : response.CurrentEmployee._id
        },
        {
            label: 'Half day',
            key: 'is_half_day',
            type: 'select',
            placeholder: 'Select the half day',
            visible: true,
            width: '25%',
            options: [
                { label: 'Yes', value: "true" },
                { label: 'No', value: "false" },
            ],
            onChange: (props) => {
                props.setObj({
                    is_half_day: props.value,
                    updateVisiblity: Math.random(),
                })
            },
            value: "false"
        },
        {
            label: 'Start Date',
            key: 'start_date',
            type: 'date',
            placeholder: 'Enter the start date',
            visible: true,
            width: '25%',
        },
        {
            label: 'End Date',
            key: 'end_date',
            type: 'date',
            placeholder: 'Enter the end date',
            visible: true,
            width: '25%',
        },
        {
            label: 'Days',
            key: 'days',
            type: 'number',
            placeholder: 'Enter the number of days',
            visible: false,
            width: '25%',
        },
        {
            label: 'Shift Half',
            key: 'shift_half',
            type: 'select',
            placeholder: 'Select the shift half',
            width: '25%',
            visible: (connectedObject) => connectedObject.is_half_day == "true",
            options: [
                { label: 'First Half', value: 'first_half' },
                { label: 'Second Half', value: 'second_half' },
            ],
        },

        // {
        //     label: '',
        //     key: 'approvers',
        //     type: 'table',
        //     placeholder: 'Select the approvers',
        //     visible: true,
        //     width: '100%',
        //     columns: [{
        //         "field": "level",
        //         "accessor": "level",
        //         "label": "Level",
        //         "Header": "Level",
        //         "editable": false,
        //         "type": "number",
        //         "visible": true,
        //         "order": 22,
        //     }, {
        //         "field": "user_ids",
        //         "accessor": "user_ids",
        //         "label": "Users",
        //         "Header": "Users",
        //         "editable": true,
        //         "type": "select",
        //         "visible": true,
        //         "order": 23,
        //         "options": response.users || [],
        //     }, {
        //         "field": "status",
        //         "accessor": "status",
        //         "label": "Status",
        //         "Header": "Status",
        //         "editable": true,
        //         "type": "select",
        //         "visible": true,
        //         "order": 24,
        //         "options": [
        //             { label: 'Pending', value: 'pending' },
        //             { label: 'Approved', value: 'approved' },
        //             { label: 'Rejected', value: 'rejected' },
        //         ],
        //     }, 
        //     {
        //         "field": "remarks",
        //         "accessor": "remarks",
        //         "label": "Remarks",
        //         "Header": "Remarks",
        //         "editable": true,
        //         "type": "text",
        //         "visible": true,
        //         "order": 25,
        //     }],
        //     actions: true,
        //     actionFunctions: [{
        //         label: 'Delete',
        //         key: 'delete',
        //         fn: ({ data, updateData, row, row_index }) => {
        //             let newData = [...data];
        //             newData.splice(row_index, 1);
        //             updateData(newData);
        //         }
        //     }],
        //     value: [],
        // },
        // {
        //     label: '',
        //     key: 'add_approver',
        //     type: 'button',
        //     visible: true,
        //     span: 24,
        //     text: 'Add Approver',
        //     onClick: (props) => {
        //         const { form, updateField } = props;
        //         let lineItems = form.approvers || [];
        //         if(!lineItems) {
        //             lineItems = [{}];
        //         } else {
        //             lineItems = [...lineItems, {}];
        //         }
        //         updateField('approvers', lineItems, form);
        //     }
        // },

        {
            label: 'Reason',
            key: 'reason',
            type: 'textarea',
            placeholder: 'Enter the reason',
            visible: true,
            width: '100%',
        },

        {
            label: 'Files',
            type: 'upload',
            placeholder: 'Please upload respective files',
            mimeType: 'image/*',
            visible: true,
            width: '100%',
            multiple: true
        },

        // {
        //     label: 'Reason for Rejection',
        //     key: 'reason_for_rejection',
        //     type: 'textarea',
        //     placeholder: 'Enter the reason for rejection',
        //     visible: true,
        //     width: '100%',
        // },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const leaveRequestMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const leaveRequestMain = {
    fields: leaveRequestfields,
    mobileCard: null
}