import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { Col, CustomCheckbox, Row } from "../../mycomponents/DynamicForm";

export const reimbursementCategoryfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

  const formFields = [
    {
      type: 'divider',
      heading: 'Claim Category Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter Name',
      visible: true,
      width: '25%',
      required: true,
    },
    {
      label: 'Expense Account',
      key: 'account_id',
      type: 'select',
      placeholder: 'Enter Expense Account',
      visible: true,
      width: '25%',
      options: (response.ExpenseAccounts || []).map((item) => {
        return {
          ...item,
          label: item.name,
          value: item._id
        }
      })
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const reimbursementCategoryMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const reimbursementCategoryMain = {
  fields: reimbursementCategoryfields,
  mobileCard: null
}