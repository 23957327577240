import { createContext, useContext, useEffect, useState } from "react"
import { RootStore, RootStoreModel } from "../RootStore"
import { setupRootStore } from "./setupRootStore"

const _rootStore = RootStoreModel.create({})
const RootStoreContext = createContext(_rootStore)
export const RootStoreProvider = RootStoreContext.Provider
export const useStores = () => useContext(RootStoreContext)

/**
 * Used only in the app.tsx file, this hook sets up the RootStore
 * and then rehydrates it. It connects everything with Reactotron
 * and then lets the app know that everything is ready to go.
 */
export const useInitialRootStore = (callback) => {
  const rootStore = useStores();
  const [rehydrated, setRehydrated] = useState(false)

  // Kick off initial async loading actions, like loading fonts and rehydrating RootStore
  useEffect(() => {
    let _unsubscribe
      ; (async () => {

        // set up the RootStore (returns the state restored from AsyncStorage)
        const { restoredState, unsubscribe } = await setupRootStore(rootStore)
        _unsubscribe = unsubscribe

        // let the app know we've finished rehydrating
        setRehydrated(true)

        // invoke the callback, if provided
        if (callback) callback()
      })()

    return () => {
      // cleanup
      if (_unsubscribe) _unsubscribe()
    }
  }, [])

  return { rootStore, rehydrated }
}
