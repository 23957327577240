import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import { Col, CustomCheckbox, Row } from "../../mycomponents/DynamicForm";

export const positionfields = (response) => {

  const formFields = [
    {
      type: 'divider',
      heading: 'Position Details',
      visible: true,
      span: 24,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      placeholder: 'Enter position Name',
      visible: true,
      width: '25%',
      required: true,
    },
    {
      label: 'Parent Position',
      key: 'parent_position_id',
      type: 'select',
      placeholder: 'Select Parent Position',
      visible: true,
      width: '25%',
      required: true,
      options: response.Positions && response.Positions.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item._id
        }
      }) || []
    },
    {
      label: 'Head Count',
      key: 'head_count',
      type: 'number',
      placeholder: 'Enter Head Count',
      visible: true,
      width: '25%',
      required: true,
    },
    {
      label: 'Related Job',
      key: 'related_job_id',
      type: 'select',
      placeholder: 'Select Related Job',
      visible: true,
      width: '25%',
      required: true,
      options: response.Jobs && response.Jobs.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item._id
        }
      }) || []
    }
  ];

  const CustomFields = response.CustomFields;

  if (CustomFields && CustomFields.length > 0) {
    CustomFields.forEach((field) => {
      formFields.push({
        label: field.name,
        key: 'cf.' + field.key,
        type: field.type,
        placeholder: field.placeholder,
        visible: true,
        width: '25%'
      });
    });
  }

  return formFields;
}

export const positionMobileCard = (item, index, getFieldValue) => {
  return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
    <View>
      <Text>TEST</Text>
      <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
    </View>
  </Card>;
}

export const positionMain = {
  fields: positionfields,
  mobileCard: null
}